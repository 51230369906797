import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';
import { Languages } from '../../constants/i18n';
import defaultSettings from '../../constants/settings.json';
import { Themes } from '../../constants/themes';
import { GenerationService } from '../../services/utils/generation.service';
import { Toast, ToastTypes } from '../../typings/toast.model';
import { AppRootState } from '../store';

const toastsEntityAdapter: EntityAdapter<Toast, string> =
  createEntityAdapter<Toast>();

type LayoutState = {
  sidebarActive: boolean;
  toasts: EntityState<Toast, string>;
  globalLoaderActive: boolean;
  lang: Languages;
  theme: Themes;
  settings: typeof defaultSettings;
  backendActive: boolean;
  fullSizeContent: boolean;
};

const initialState: LayoutState = {
  sidebarActive: true,
  toasts: toastsEntityAdapter.getInitialState(),
  globalLoaderActive: false,
  lang: Languages.ENGLISH,
  theme: Themes.LIGHT,
  settings: defaultSettings,
  backendActive: false,
  fullSizeContent: false
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidebarActive: (state: LayoutState, action: PayloadAction<boolean>) => {
      state.sidebarActive = action.payload;
    },
    addToast: (state: LayoutState, action: PayloadAction<Toast>) => {
      toastsEntityAdapter.addOne(state.toasts, action.payload);
    },
    addSuccessToast: (state: LayoutState, action: PayloadAction<string>) => {
      toastsEntityAdapter.addOne(state.toasts, {
        id: GenerationService.generateUUID(),
        type: ToastTypes.SUCCESS,
        message: action.payload
      });
    },
    addErrorToast: (state: LayoutState, action: PayloadAction<string>) => {
      toastsEntityAdapter.addOne(state.toasts, {
        id: GenerationService.generateUUID(),
        type: ToastTypes.ERROR,
        message: action.payload
      });
    },
    addInfoToast: (state: LayoutState, action: PayloadAction<string>) => {
      toastsEntityAdapter.addOne(state.toasts, {
        id: GenerationService.generateUUID(),
        type: ToastTypes.INFO,
        message: action.payload
      });
    },
    addWarningToast: (state: LayoutState, action: PayloadAction<string>) => {
      toastsEntityAdapter.addOne(state.toasts, {
        id: GenerationService.generateUUID(),
        type: ToastTypes.WARNING,
        message: action.payload
      });
    },
    removeToasts: (state: LayoutState, action: PayloadAction<string[]>) => {
      toastsEntityAdapter.removeMany(state.toasts, action.payload);
    },
    setGlobalLoaderActive: (
      state: LayoutState,
      action: PayloadAction<boolean>
    ) => {
      state.globalLoaderActive = action.payload;
    },
    setLang: (state: LayoutState, action: PayloadAction<Languages>) => {
      state.lang = action.payload;
    },
    setTheme: (state: LayoutState, action: PayloadAction<Themes>) => {
      state.theme = action.payload;
    },
    setSettings: (
      state: LayoutState,
      action: PayloadAction<typeof defaultSettings>
    ) => {
      state.settings = action.payload;
    },
    setBackendActive: (state: LayoutState, action: PayloadAction<boolean>) => {
      state.backendActive = action.payload;
    },
    setFullSizeContent: (
      state: LayoutState,
      action: PayloadAction<boolean>
    ) => {
      state.fullSizeContent = action.payload;
    }
  }
});

export const layoutActions = {
  setSidebarActive: layoutSlice.actions.setSidebarActive,
  addToast: layoutSlice.actions.addToast,
  addSuccessToast: layoutSlice.actions.addSuccessToast,
  addErrorToast: layoutSlice.actions.addErrorToast,
  addInfoToast: layoutSlice.actions.addInfoToast,
  addWarningToast: layoutSlice.actions.addWarningToast,
  removeToasts: layoutSlice.actions.removeToasts,
  setGlobalLoaderActive: layoutSlice.actions.setGlobalLoaderActive,
  setLang: layoutSlice.actions.setLang,
  setTheme: layoutSlice.actions.setTheme,
  setSettings: layoutSlice.actions.setSettings,
  setBackendActive: layoutSlice.actions.setBackendActive,
  setFullSizeContent: layoutSlice.actions.setFullSizeContent
};

export const layoutSelectors = {
  selectSidebarActive: (state: AppRootState) => state.layout.sidebarActive,
  selectToasts: toastsEntityAdapter.getSelectors<AppRootState>(
    (state) => state.layout.toasts
  ).selectAll,
  selectGlobalLoaderActive: (state: AppRootState) =>
    state.layout.globalLoaderActive,
  selectLang: (state: AppRootState) => state.layout.lang,
  selectTheme: (state: AppRootState) => state.layout.theme,
  selectSettings: (state: AppRootState) => state.layout.settings,
  selectBackendActive: (state: AppRootState) => state.layout.backendActive,
  selectFullSizeContent: (state: AppRootState) => state.layout.fullSizeContent
};

export const layoutReducer = layoutSlice.reducer;
