export enum ToastTypes {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

export interface Toast {
  id: string;
  type: ToastTypes;
  message: string;
}
