import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import findLast from 'lodash/findLast';
import {
  SensorReadingsChange,
  ShortMockSensorReading
} from '../../typings/sensorReadings.model';
import { AppRootState } from '../store';

type SensorReadingsState = {
  shortMockSensorReadings: GroupedShortMockSensorReadingState[];
};

export interface GroupedShortMockSensorReadingState {
  timestamp: string;
  readings: ShortMockSensorReading[];
}

const initialState: SensorReadingsState = {
  shortMockSensorReadings: []
};

const sensorReadingsSlice = createSlice({
  name: 'sensorReadings',
  initialState,
  reducers: {
    addShortMockStreamSensorReadings: (
      state: SensorReadingsState,
      action: PayloadAction<SensorReadingsChange>
    ) => {
      const groupedReadings = {
        timestamp: action.payload.readings[0].tagTransmittedAt,
        readings: action.payload.readings
      };
      action.payload.currentTagNames.forEach((tn) => {
        if (groupedReadings.readings.find((reading) => reading.id === tn)) {
          return;
        }
        const lastReadingOfType = findLast(
          state.shortMockSensorReadings,
          (rg) => rg.readings.some((r) => r.tagName === tn)
        );
        if (lastReadingOfType) {
          const typedLrot: any = lastReadingOfType;
          groupedReadings.readings.push(
            typedLrot.readings.find(
              (r: ShortMockSensorReading) => r.tagName === tn
            )
          );
        }
      });
      state.shortMockSensorReadings =
        state.shortMockSensorReadings.concat(groupedReadings);
    },
    removeAllShortMockStreamSensorReadings: (
      state: SensorReadingsState,
      _action: PayloadAction<null>
    ) => {
      state.shortMockSensorReadings = [];
    }
  }
});

export const sensorReadingsActions = {
  addShortMockStreamSensorReadings:
    sensorReadingsSlice.actions.addShortMockStreamSensorReadings,
  removeAllShortMockStreamSensorReadings:
    sensorReadingsSlice.actions.removeAllShortMockStreamSensorReadings
};

export const sensorReadingsSelectors = {
  selectShortMockSensorReadings: (state: AppRootState) =>
    state.sensorReadings.shortMockSensorReadings
};

export const sensorReadingsReducer = sensorReadingsSlice.reducer;
