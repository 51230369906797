import { Button, Drawer, Message } from '@arco-design/web-react';
import { IconSettings } from '@arco-design/web-react/icon';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { ButtonSize, ButtonType } from '../../constants/arcoEnums';
import useLocale from '../../hooks/useLocale';
import { layoutSelectors } from '../../redux/slices/layout.slice';
import { useAppSelector } from '../../redux/store';
import NavbarIconButton from '../Navbar/NavbarIconButton';
import SettingsBlock from './SettingsBlock';
import SettingsColor from './SettingsColor';
import locale from './locale/locale';

type Props = {};

function Setting(_props: Props) {
  const [visible, setVisible] = useState(false);
  const t = useLocale(locale);

  const settings = useAppSelector(layoutSelectors.selectSettings);

  function onCopySettings() {
    copy(JSON.stringify(settings, null, 2));
    Message.success(t['settings.copySettings.message']);
  }

  return (
    <>
      {settings.navbar ? (
        <NavbarIconButton
          icon={<IconSettings />}
          onClick={() => setVisible(true)}
        />
      ) : (
        <Button
          size={ButtonSize.DEFAULT}
          type={ButtonType.PRIMARY}
          icon={<IconSettings />}
          onClick={() => setVisible(true)}
        />
      )}
      <Drawer
        width={300}
        title={
          <>
            <IconSettings className="mr-2" />
            {t['settings.title']}
          </>
        }
        visible={visible}
        okText={t['settings.copySettings']}
        cancelText={t['settings.close']}
        onOk={onCopySettings}
        onCancel={() => setVisible(false)}
      >
        <SettingsBlock title={t['settings.themeColor']}>
          <SettingsColor />
        </SettingsBlock>
        <SettingsBlock
          title={t['settings.content']}
          options={[
            { name: 'settings.navbar', value: 'navbar' },
            { name: 'settings.menu', value: 'menu' },
            { name: 'settings.footer', value: 'footer' },
            { name: 'settings.menuWidth', value: 'menuWidth', type: 'number' }
          ]}
        />
        {/* TODO - high contrast mode */}
        {/* <SettingsBlock
          title={t['settings.otherSettings']}
          options={[{ name: 'settings.colorWeak', value: 'colorWeak' }]}
        /> */}
      </Drawer>
    </>
  );
}

export default Setting;
