import { useEffect, useState } from 'react';
import { StorageKeys } from '../constants/storageKeys';

const getDefaultStorage = (key: StorageKeys) => {
  return localStorage.getItem(key);
};

function useStorage(
  key: StorageKeys,
  defaultValue?: string
): [value: string | undefined, (value: string) => void, () => void] {
  const [storedValue, setStoredValue] = useState(
    getDefaultStorage(key) || defaultValue
  );

  const setStorageValue = (value: string) => {
    localStorage.setItem(key, value);
    if (value !== storedValue) {
      setStoredValue(value);
    }
  };

  const removeStorage = () => {
    localStorage.removeItem(key);
  };

  useEffect(() => {
    const storageValue = localStorage.getItem(key);
    if (storageValue) {
      setStoredValue(storageValue);
    }
  }, [key]);

  return [storedValue, setStorageValue, removeStorage];
}

export default useStorage;
