import { createApi } from '@reduxjs/toolkit/query/react';
import { API_HEX_SUBPATH } from '../../constants/web';
import { HexProjectsResponse } from '../../typings/hex.model';
import { axiosBaseQuery } from './baseClients';

export const hexApi = createApi({
  reducerPath: 'hexApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/${API_HEX_SUBPATH}`
  }),
  tagTypes: ['HexProject'],
  endpoints: (builder) => ({
    getProjects: builder.query<HexProjectsResponse, null>({
      query: () => ({ url: '/projects', method: 'GET' }),
      providesTags: ['HexProject']
    })
  })
});

export const { useGetProjectsQuery } = hexApi;
