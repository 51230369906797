import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { environmentVariables } from '../../constants/environment';
import { API_PREFIX_PATH, PROTOCOL_HTTP } from '../../constants/web';
import { AuthService } from '../../services/data/auth.data.service';

const BASE_TIMEOUT = 10000;

const axiosInstance = axios.create({
  baseURL: `${PROTOCOL_HTTP}://${environmentVariables.VITE_API_HOSTNAME}/${API_PREFIX_PATH}`,
  timeout: BASE_TIMEOUT
});

axiosInstance.interceptors.request.use(async (config) => {
  // ~2 minutes timeout for media uploads
  if (config?.data instanceof FormData) {
    config.timeout = 100000;
  }

  const authService = new AuthService();
  const session = await authService.getSession();
  if (session.data.session?.access_token) {
    config.headers.Authorization = `Bearer ${session.data.session?.access_token}`;
  }

  return config;
});

// * vide https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };
