import { SupabaseClient } from '@supabase/supabase-js';
import {
  AVATARS_BUCKET_FOLDER_NAME,
  FileTypes,
  IMAGES_BUCKET_NAME
} from '../../constants/files';
import { storageSchemaClient } from '../../db/db';
import { Database as StorageDatabase } from '../../db/schemas/storage.schema';
import { NotImplementedError } from '../../errors/errors';
import { BucketObject, FileUploadResult } from '../../typings/files.model';
import { Try } from '../../typings/web.model';
import { ConversionService } from '../utils/conversion.service';

export class StorageService {
  storageClient: SupabaseClient<StorageDatabase>;

  constructor() {
    this.storageClient = storageSchemaClient;
  }

  // TODO - type via `Try` wrapper
  selectAllBuckets = async () => {
    const { data, error } = await this.storageClient.storage.listBuckets();
    return { data, error };
  };

  uploadFile: (
    file: File,
    fileType: FileTypes,
    filename: string
  ) => Promise<Try<FileUploadResult, Error>> = async (
    file,
    fileType,
    filename
  ) => {
    let data;
    let error;
    if (fileType === FileTypes.AVATAR) {
      const { data: avatarData, error: avatarError } =
        await this.storageClient.storage
          .from(IMAGES_BUCKET_NAME)
          .upload(`${AVATARS_BUCKET_FOLDER_NAME}/${filename}`, file);
      data = avatarData;
      error = avatarError;
    } else {
      throw new NotImplementedError('File type upload not implemented');
    }
    return { data, error };
  };

  selectFileById: (fileId: string) => Promise<Try<BucketObject, Error>> =
    async (avatarId: string) => {
      const { data, error } = await this.storageClient
        .from('objects')
        .select('*')
        .eq('id', avatarId)
        .single();
      const service = new ConversionService<BucketObject>();
      const convertedData = data ? service.toCamelCase(data) : null;
      return { data: convertedData, error };
    };

  selectPublicUrlByPath: (path: string) => Promise<string> = async (
    path: string
  ) => {
    const { data } = await this.storageClient.storage
      .from(IMAGES_BUCKET_NAME)
      .getPublicUrl(path);
    return data.publicUrl;
  };
}
