import { createApi } from '@reduxjs/toolkit/query/react';
import { extname } from 'path';
import { API_AUTH_SUBPATH } from '../../constants/web';
import { AuthService } from '../../services/data/auth.data.service';
import { StorageService } from '../../services/data/storage.data.service';
import { UserDetailsService } from '../../services/data/user-details.data.service';
import { GenerationService } from '../../services/utils/generation.service';
import { AvatarUploadRequest } from '../../typings/files.model';
import {
  FetchUsersAdminResponse,
  InviteUserAdminRequest,
  InviteUserAdminResponse,
  LoginRequest,
  SupabaseLoginResult,
  SupabaseLogoutResult,
  SupabaseUser
} from '../../typings/users.model';
import { SingleItemRequest } from '../../typings/web.model';
import { AppError, AppErrorAuth } from './../../errors/errors';
import {
  CreateUserDetails,
  UserDetails
} from './../../typings/userDetails.model';
import { axiosBaseQuery } from './baseClients';

const authService = new AuthService();
const userDetailsService = new UserDetailsService();
const storageService = new StorageService();

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/${API_AUTH_SUBPATH}`
  }),
  tagTypes: ['User', 'UserDetails', 'AdminUsers'],
  endpoints: (builder) => ({
    // TODO - simplify this typings with `Try`
    loginUser: builder.query<SupabaseLoginResult, LoginRequest>({
      queryFn: async ({ user, password }) => {
        const loginResult = await authService.login(user, password);
        if (loginResult.error) {
          throw new AppErrorAuth(loginResult.error.message);
        }
        return { data: loginResult };
      },
      providesTags: ['User']
    }),

    // TODO - simplify this typings with `Try`
    logoutUser: builder.mutation<SupabaseLogoutResult, {}>({
      queryFn: async () => {
        const logoutResult = await authService.logout();
        if (logoutResult.error) {
          throw new AppErrorAuth(logoutResult.error.message);
        }
        return { data: logoutResult };
      },
      invalidatesTags: ['User']
    }),

    getUserDetails: builder.query<UserDetails, SingleItemRequest>({
      queryFn: async ({ id }) => {
        const userDetails =
          await userDetailsService.selectUserDetailsByAuthId(id);
        if (userDetails.error || !userDetails.data) {
          throw new AppError(
            userDetails.error?.message ?? 'No user details found'
          );
        }
        return { data: userDetails.data };
      },
      providesTags: ['UserDetails']
    }),

    createUserDetails: builder.mutation<UserDetails, CreateUserDetails>({
      queryFn: async (data) => {
        const updatedUserDetails =
          await userDetailsService.createUserDetails(data);

        if (updatedUserDetails.error || !updatedUserDetails.data) {
          throw new AppError(
            updatedUserDetails.error?.message ?? 'User details creation error'
          );
        }
        return { data: updatedUserDetails.data };
      },
      invalidatesTags: ['UserDetails']
    }),

    updateUserDetails: builder.mutation<
      UserDetails,
      { id: string; details: Partial<UserDetails> }
    >({
      queryFn: async (data) => {
        const updatedUserDetails =
          await userDetailsService.updateUserDetailsById(data.id, data.details);
        if (updatedUserDetails.error || !updatedUserDetails.data) {
          throw new AppError(
            updatedUserDetails.error?.message ?? 'User details update error'
          );
        }
        return { data: updatedUserDetails.data };
      },
      invalidatesTags: ['UserDetails']
    }),

    updateUserAvatar: builder.mutation<UserDetails, AvatarUploadRequest>({
      queryFn: async (data) => {
        const storageFilename: string = `${data.userDetailsId}_${GenerationService.generateUUID()}_${extname(data.filename)}`;
        const avatarUploadResult = await storageService.uploadFile(
          data.file,
          data.fileType,
          storageFilename
        );
        if (avatarUploadResult.error || !avatarUploadResult.data) {
          throw new AppError(
            avatarUploadResult.error?.message ?? 'Avatar upload error'
          );
        }
        const updatedUserDetails =
          await userDetailsService.updateUserDetailsById(data.userDetailsId, {
            avatarId: avatarUploadResult.data.id
          });
        if (updatedUserDetails.error || !updatedUserDetails.data) {
          throw new AppError(
            updatedUserDetails.error?.message ?? 'User details update error'
          );
        }
        return { data: updatedUserDetails.data };
      },
      invalidatesTags: ['UserDetails']
    }),

    fetchUsersAdmin: builder.query<FetchUsersAdminResponse, null>({
      query: () => ({ url: '/users', method: 'GET' }),
      providesTags: ['AdminUsers']
      // TODO - find a way to automatically type SupabaseUser
      // * incoming type is snake_case
      // transformResponse: (response: FetchUsersAdminResponse) =>
      //   new ConversionService<FetchUsersAdminResponse>().toCamelCase(response)
    }),

    inviteUserAdmin: builder.mutation<
      InviteUserAdminResponse,
      InviteUserAdminRequest
    >({
      query: (req) => ({ url: '/invite_user', method: 'POST', data: req }),
      invalidatesTags: ['AdminUsers']
      // TODO - find a way to automatically type SupabaseUser
      // * incoming type is snake_case
      // transformResponse: (response: InviteUserAdminResponse) =>
      //   new ConversionService<InviteUserAdminResponse>().toCamelCase(response)
    }),

    updateCurrentUserPassword: builder.mutation<
      SupabaseUser,
      { password: string }
    >({
      queryFn: async (data) => {
        const result = await authService.changeCurrentUSerPassword(
          data.password
        );
        if (result.error || !result.data) {
          throw new AppError(
            result.error?.message ?? 'User password update error'
          );
        }
        return { data: result.data };
      }
    })
  })
});

export const {
  useLazyLoginUserQuery,
  useLogoutUserMutation,
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useUpdateUserAvatarMutation,
  useCreateUserDetailsMutation,
  useLazyGetUserDetailsQuery,
  useFetchUsersAdminQuery,
  useInviteUserAdminMutation,
  useUpdateCurrentUserPasswordMutation
} = usersApi;
