import { PermissionTypes } from './permissions';

export enum NavigationRoutes {
  HOME = '/',
  LOGIN = '/login',
  VISUALIZATION_DASHBOARDS = '/visualization/dashboards',
  VISUALIZATION_TRENDS = '/visualization/trends',
  VISUALIZATION_BASELINE = '/visualization/baseline',
  ANALYSIS_INSIGHTS = '/analysis/insights',
  ANALYSIS_MODELS = '/analysis/models',
  ANALYSIS_TEA_BUILDER = '/analysis/tea-builder',
  DATA_INPUTS = '/data/inputs',
  USER_SETTINGS = '/user/settings',
  USER_INVITATIONS = '/user/invitations',
  SETUP_PASSWORD = '/setup-password'
}

export enum ParentRoutes {
  VISUALIZATION = '/visualization',
  ANALYSIS = '/analysis',
  DATA = '/data',
  USER = '/user'
}

export type IRoute = {
  // name of the route, maps to the locale key
  name: string;
  // unique route key, maps to URL path
  key: NavigationRoutes | ParentRoutes;
  // required permissions for the route
  requiredPermissions: Set<PermissionTypes>;
  // subroutes mapped into sider items (defaults to null)
  children: IRoute[];
  // disable the route (defaults to false)
  disabled?: boolean;
};

const visMenuItems: IRoute[] = [
  {
    key: NavigationRoutes.VISUALIZATION_DASHBOARDS,
    name: 'menu.visualization.dashboards',
    requiredPermissions: new Set(['visualization_dashboards.read']),
    children: []
  },
  {
    key: NavigationRoutes.VISUALIZATION_TRENDS,
    name: 'menu.visualization.trends',
    requiredPermissions: new Set(['visualization_trends.read']),
    children: []
  },
  {
    key: NavigationRoutes.VISUALIZATION_BASELINE,
    name: 'menu.visualization.baseline',
    requiredPermissions: new Set(['visualization_baseline.read']),
    children: []
  }
];

const analysisMenuItems: IRoute[] = [
  {
    key: NavigationRoutes.ANALYSIS_INSIGHTS,
    name: 'menu.analysis.insights',
    requiredPermissions: new Set(['analysis_insights.read']),
    children: [],
    disabled: true
  },
  {
    key: NavigationRoutes.ANALYSIS_MODELS,
    name: 'menu.analysis.models',
    requiredPermissions: new Set(['analysis_models.read']),
    children: []
  },
  {
    key: NavigationRoutes.ANALYSIS_TEA_BUILDER,
    name: 'menu.analysis.teaBuilder',
    requiredPermissions: new Set(['analysis_tea_builder.read']),
    children: [],
    disabled: true
  }
];

const dataMenuItems: IRoute[] = [
  {
    key: NavigationRoutes.DATA_INPUTS,
    name: 'menu.data.inputs',
    requiredPermissions: new Set(['data_inputs.read']),
    children: [],
    disabled: true
  }
];

const userMenuItems: IRoute[] = [
  {
    key: NavigationRoutes.USER_SETTINGS,
    name: 'menu.user.settings',
    requiredPermissions: new Set(['user_settings.read']),
    children: []
  },
  {
    key: NavigationRoutes.USER_INVITATIONS,
    name: 'menu.user.invitations',
    requiredPermissions: new Set(['user_invitations.read']),
    children: []
  }
];

export const layoutRoutes: IRoute[] = [
  {
    key: ParentRoutes.VISUALIZATION,
    name: 'menu.visualization',
    requiredPermissions: new Set(),
    children: visMenuItems
  },
  {
    key: ParentRoutes.ANALYSIS,
    name: 'menu.analysis',
    requiredPermissions: new Set(),
    children: analysisMenuItems
  },
  {
    key: ParentRoutes.DATA,
    name: 'menu.data',
    requiredPermissions: new Set(),
    children: dataMenuItems
  },
  {
    key: ParentRoutes.USER,
    name: 'menu.user',
    requiredPermissions: new Set(),
    children: userMenuItems
  }
];

export type MatchRouteResults = {
  parent: {
    name: string;
    key: ParentRoutes;
  } | null;
  child: {
    name: string;
    key: NavigationRoutes;
  } | null;
};

export const matchRoutes = (
  parentRoutes: IRoute[],
  key: NavigationRoutes | ParentRoutes
): MatchRouteResults => {
  let foundParentName: string | null = null;
  let foundParentKey: ParentRoutes | null = null;
  let foundChildName: string | null = null;
  let foundChildKey: NavigationRoutes | null = null;
  parentRoutes.forEach((parent) => {
    if (key === parent.key) {
      foundParentName = parent.name;
      foundParentKey = parent.key as ParentRoutes | null;
    }
    parent.children.forEach((child) => {
      if (child.key === key) {
        foundParentName = parent.name;
        foundParentKey = parent.key as ParentRoutes | null;
        foundChildName = child.name;
        foundChildKey = child.key as NavigationRoutes | null;
      }
    });
  });
  return {
    parent: (foundParentName
      ? { name: foundParentName, key: foundParentKey }
      : null) as {
      name: string;
      key: ParentRoutes;
    } | null,
    child: (foundChildName
      ? { name: foundChildName, key: foundChildKey }
      : null) as {
      name: string;
      key: NavigationRoutes;
    } | null
  };
};
