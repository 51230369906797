import { Layout } from '@arco-design/web-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import useLocale from '../../hooks/useLocale';
import locale from './locale/locale';
import styles from './style/footer.module.less';

type Props = {
  className?: string;
};

const Footer = ({ className }: Props) => {
  const t = useLocale(locale);
  return (
    <Layout.Footer className={clsx(styles.footer, className)}>
      {`${t['footer.label']} ${dayjs().format('YYYY')}`}
    </Layout.Footer>
  );
};

export default Footer;
