import enUS from '@arco-design/web-react/es/locale/en-US';
import { Languages } from '../../constants/i18n';

export class InternationalizationService {
  static getMappedArcoLocale(lang: Languages) {
    switch (lang) {
      case Languages.ENGLISH:
        return enUS;
      default:
        return enUS;
    }
  }
}
