import { ConfigProvider } from '@arco-design/web-react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Can from './components/auth/Can/Can';
import RequireAuth from './components/auth/RequireAuth/RequireAuth';
import HealthCheck from './components/HealthCheck/HealthCheck';
import Session from './components/Session/Session';
import Toaster from './components/Toaster/Toaster';
import { environmentVariables, ViteEnvironment } from './constants/environment';
import { Languages } from './constants/i18n';
import { NavigationRoutes } from './constants/routes';
import { StorageKeys } from './constants/storageKeys';
import usePermissions from './hooks/usePermissions';
import useStorage from './hooks/useStorage';
import Insights from './pages/analysis/insights/Insights.page';
import Models from './pages/analysis/models/Models.page';
import TeaBuilder from './pages/analysis/teaBuilder/TeaBuilder.page';
import Inputs from './pages/data/inputs/Inputs.page';
import Forbidden from './pages/forbidden/Forbidden.page';
import BaseLayout from './pages/layout/Layout.page';
import Login from './pages/login/Login.page';
import SetupPassword from './pages/setup_password/SetupPassword.page';
import Invitations from './pages/user/invitations/Invitations.page';
import UserSettings from './pages/user/settings/UserSettings.page';
import Baseline from './pages/visualization/baseline/Baseline.page';
import Dashboards from './pages/visualization/dashboards/Dashboards.page';
import Trends from './pages/visualization/trends/Trends.page';
import { layoutActions, layoutSelectors } from './redux/slices/layout.slice';
import { useAppDispatch, useAppSelector } from './redux/store';
import { AuthService } from './services/data/auth.data.service';
import { InternationalizationService } from './services/utils/i18n.service';
import styles from './styles/app.module.less';

function App() {
  const [initTriggered, setInitTriggered] = useState(false);

  const dispatch = useAppDispatch();
  const lang: Languages = useAppSelector(layoutSelectors.selectLang);
  const { uniquePermissions } = usePermissions();

  const [storageLang] = useStorage(StorageKeys.LANG);

  useLayoutEffect(() => {
    if (storageLang) {
      dispatch(layoutActions.setLang(storageLang as Languages));
    }
  }, [dispatch, storageLang]);

  const printJwtRoleInfo = async () => {
    const res = await new AuthService().getJwtAuthorizedRoleInfo();
    // eslint-disable-next-line no-console
    console.log('JWT User Info', res);
  };

  useEffect(() => {
    if (!initTriggered) {
      if (environmentVariables.MODE === ViteEnvironment.DEVELOPMENT) {
        // eslint-disable-next-line no-console
        console.log('Environment variables', environmentVariables);
        printJwtRoleInfo();
      }
      setInitTriggered(true);
    }
  }, [initTriggered]);

  return (
    <ConfigProvider
      locale={InternationalizationService.getMappedArcoLocale(lang)}
      componentConfig={{
        Card: {
          bordered: false
        },
        List: {
          bordered: false
        },
        Table: {
          border: false
        }
      }}
    >
      <div className={styles['app']}>
        <Routes>
          <Route path={NavigationRoutes.LOGIN} element={<Login />} />
          <Route
            path={NavigationRoutes.SETUP_PASSWORD}
            element={<SetupPassword />}
          />
          <Route
            element={
              <RequireAuth>
                <BaseLayout />
              </RequireAuth>
            }
          >
            <Route
              path={NavigationRoutes.HOME}
              element={
                <Navigate to={NavigationRoutes.VISUALIZATION_DASHBOARDS} />
              }
            />
            <Route
              path={NavigationRoutes.VISUALIZATION_DASHBOARDS}
              element={
                <Can
                  requiredPermissions={
                    new Set(['visualization_dashboards.read'])
                  }
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <Dashboards /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.VISUALIZATION_TRENDS}
              element={
                <Can
                  requiredPermissions={new Set(['visualization_trends.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <Trends /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.VISUALIZATION_BASELINE}
              element={
                <Can
                  requiredPermissions={new Set(['visualization_baseline.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <Baseline /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.ANALYSIS_INSIGHTS}
              element={
                <Can
                  requiredPermissions={new Set(['analysis_insights.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <Insights /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.ANALYSIS_MODELS}
              element={
                <Can
                  requiredPermissions={new Set(['analysis_models.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <Models /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.ANALYSIS_TEA_BUILDER}
              element={
                <Can
                  requiredPermissions={new Set(['analysis_tea_builder.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <TeaBuilder /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.DATA_INPUTS}
              element={
                <Can
                  requiredPermissions={new Set(['data_inputs.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <Inputs /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.USER_SETTINGS}
              element={
                <Can
                  requiredPermissions={new Set(['user_settings.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <UserSettings /> : <Forbidden />)}
                </Can>
              }
            />
            <Route
              path={NavigationRoutes.USER_INVITATIONS}
              element={
                <Can
                  requiredPermissions={new Set(['user_invitations.read'])}
                  userPermissions={uniquePermissions}
                >
                  {(allowed) => (allowed ? <Invitations /> : <Forbidden />)}
                </Can>
              }
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to={NavigationRoutes.HOME} replace />}
          />
        </Routes>
      </div>

      <Toaster />

      <Session />

      <HealthCheck />
    </ConfigProvider>
  );
}

export default App;

// TODO - implement arias for all interactive DOM elements
// TODO - switch from `HashRouter` to `BrowserRouter` when going away from GH Pages

// TODO - Readme - add ENV info
// TODO - Readme - add project desc section

// TODO - decide what to show on Not Found page
// TODO - implement forgot password

// TODO - arco forms - trim values before apply

// TODO - go through all locale translations and remove unused keys
// TODO - remove chinese locales
// TODO - add generic typing to locale files
// TODO - type keys within locale files to language enum
// TODO - create fallback mechanism to print passed keys if no record found in locale

// TODO - check if changes from lang switcher have to be propagated to `ConfigProvider` in `App.tsx`

// TODO - WS client optimizations
// TODO - WS - listen to `visibilitychange` event and pause WS when tab is not visible
// TODO - WS - send data in binary format within WS to reduce payload size (Protocol Buffers or MessagePack)
// TODO - WS - verify WS reconnection logic

// TODO - `layout.module.less` enforces min-width of 1100px - correct this when doing RWD later
// TODO - apply some min-width

// TODO - possibly remove `style` prop from all components
// TODO - remove `settings.json` and just put defaults inside store

// TODO - you can transform images when DLing them - do it for avatar https://supabase.com/docs/guides/storage/serving/image-transformations?queryGroups=language&language=js

// TODO - type & map all errors to one file
// TODO - write global way to display errors from RTK
// TODO - deserialize FastAPI errors to be more user-friendly and display them within Toast
// TODO - deserialize Supabase errors to be more user-friendly and display them within Toast

// TODO - try to join address via supabase query

// TODO - write BE CRON cleanup for unused avatar files

// TODO - write check permission HOC and wrap layout routes in it to navigate to default route
// TODO - fix routes `disable` bug in `routes.ts`

// TODO - group scripts into `scripts` folder
// TODO - group docker into `docker` folder

// TODO - Exposed schemas programatically in remote - verify if SQL on the end  works on remote - https://github.com/CVector-Energy/supabase_handler/blob/5267860bf0f7244c4f3f95b11b8b13b47b10a5c1/supabase/migrations/20250113122315_roles_permissions.sql

// TODO - update types automatically vide https://supabase.com/docs/guides/api/rest/generating-types#update-types-automatically-with-github-actions & https://github.com/CVector-Energy/supabase_handler

// TODO - extract SetupPassword, Login & other non-layout components into generic component

// TODO - implement more elaborate password requirements

// TODO - enable required SSL for Supabase DB and configure it

// TODO - write in documentation & README basic structure of data flow within the project (Component -> RTK -> HTTP/Clients -> RTK -> Slice -> Component)

// TODO - invitation setup password cache - refresh user data after password setup so that user confirmation status is updated within user settings

// TODO - consider changing toaster into Arco `Notification` component

// TODO - add import TW colors into JS - https://stackoverflow.com/a/77346945

// TODO - add Ably banchmark

// TODO - disallow IE & FF from browsing our page

// TODO - fix colors for high contrast mode and enable it within navbar settings panel
