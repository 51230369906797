import ContentPage from '../../../components/ContentPage/ContentPage';
import UnderConstruction from '../../../components/UnderConstruction/UnderConstruction';

type Props = {};

const Baseline = (_props: Props) => {
  return (
    <ContentPage title="Baseline">
      <UnderConstruction />
    </ContentPage>
  );
};

export default Baseline;
