import ContentPage from '../../../components/ContentPage/ContentPage';
import UnderConstruction from '../../../components/UnderConstruction/UnderConstruction';

type Props = {};

const Dashboards = (_props: Props) => {
  return (
    <ContentPage title="Dashboards">
      <UnderConstruction />
    </ContentPage>
  );
};

export default Dashboards;
