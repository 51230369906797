import { UserDetails } from '../../typings/userDetails.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoggedInUser } from '../../typings/users.model';
import { AppRootState } from '../store';

type LayoutState = {
  currentUser: LoggedInUser | null;
  userDetails: UserDetails | null;
};

const initialState: LayoutState = {
  currentUser: null,
  userDetails: null
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setCurrentUser: (
      state: LayoutState,
      action: PayloadAction<LoggedInUser | null>
    ) => {
      state.currentUser = action.payload;
    },
    setUserDetails: (
      state: LayoutState,
      action: PayloadAction<UserDetails | null>
    ) => {
      state.userDetails = action.payload;
    }
  }
});

export const usersActions = {
  setCurrentUser: usersSlice.actions.setCurrentUser,
  setUserDetails: usersSlice.actions.setUserDetails
};

export const usersSelectors = {
  selectCurrentUser: (state: AppRootState) => state.users.currentUser,
  selectUserDetails: (state: AppRootState) => state.users.userDetails,
  selectUserPermissions: (state: AppRootState) =>
    state.users.currentUser?.permissions
};

export const usersReducer = usersSlice.reducer;
