import { useMemo } from 'react';
import { OptionalPermissions, PermissionTypes } from '../constants/permissions';
import { usersSelectors } from '../redux/slices/users.slice';
import { useAppSelector } from '../redux/store';

function usePermissions(): {
  userPermissions: OptionalPermissions;
  uniquePermissions: Set<PermissionTypes>;
} {
  const userPermissions: OptionalPermissions = useAppSelector(
    usersSelectors.selectUserPermissions
  );

  const uniquePermissions = useMemo(
    () =>
      userPermissions
        ? new Set<PermissionTypes>(userPermissions)
        : new Set<PermissionTypes>(),
    [userPermissions]
  );

  return { userPermissions, uniquePermissions };
}

export default usePermissions;
