import { useEffect, useState } from 'react';
import { useGetProjectsQuery } from '../../../communications/api/hex.api';
import ContentPage from '../../../components/ContentPage/ContentPage';
import { hexSelectors } from '../../../redux/slices/hex.slice';
import { layoutActions } from '../../../redux/slices/layout.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import ModelsAdminProjectsChoice from './ModelsAdminProjectsChoice';
import ModelsProjectDisplay from './ModelsProjectDisplay';
import ModelsProjectsTable from './ModelsProjectsTable';
import { HexProject } from '../../../typings/hex.model';

type Props = {};

export enum ModelsPageSteps {
  PROJECTS_CHOICE,
  PROJECT_DISPLAY
}

const Models = (_props: Props) => {
  const [pageStep, setPageStep] = useState<ModelsPageSteps>(
    ModelsPageSteps.PROJECTS_CHOICE
  );
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [chosenProject, setChosenProject] = useState<HexProject | null>(null);

  const dispatch = useAppDispatch();
  const visibleIds: string[] = useAppSelector(
    hexSelectors.selectSetVisibleProjectIds
  );
  const {
    data: projectsData,
    error: projectsError,
    isLoading: projectsLoading
  } = useGetProjectsQuery(null);

  useEffect(() => {
    if (projectsError) {
      dispatch(layoutActions.addErrorToast('Hex projects fetch failed'));
    }
  }, [projectsError, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(layoutActions.setFullSizeContent(false));
    };
  }, [dispatch]);

  const invokeRefresh = () => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 0);
  };

  return (
    <>
      <ContentPage title="Models">
        {pageStep === ModelsPageSteps.PROJECTS_CHOICE && !refreshing && (
          <>
            <ModelsAdminProjectsChoice
              projects={projectsData?.projects}
              loading={projectsLoading}
              visibleIds={visibleIds}
            />
            <ModelsProjectsTable
              projects={projectsData?.projects}
              loading={projectsLoading}
              visibleIds={visibleIds}
              setPageStep={setPageStep}
              setChosenProject={setChosenProject}
            />
          </>
        )}

        {pageStep === ModelsPageSteps.PROJECT_DISPLAY &&
          chosenProject &&
          !refreshing && (
            <ModelsProjectDisplay
              chosenProject={chosenProject}
              setPageStep={setPageStep}
              setChosenProject={setChosenProject}
              invokeRefresh={invokeRefresh}
            />
          )}
      </ContentPage>
    </>
  );
};

export default Models;
