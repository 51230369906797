import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { PermissionTypes } from '../../constants/permissions';
import { usersActions } from '../../redux/slices/users.slice';
import { useAppDispatch } from '../../redux/store';
import { AuthService } from '../../services/data/auth.data.service';
import { SupabaseUser } from '../../typings/users.model';

type Props = {};

const Session = (_props: Props) => {
  const [initTriggered, setInitTriggered] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!initTriggered) {
      const verifySession = async () => {
        const authService = new AuthService();
        const sbSession = await authService.getSession();
        if (sbSession.data.session?.access_token) {
          const decodedUser: SupabaseUser = jwtDecode<SupabaseUser>(
            sbSession.data.session.access_token
          );
          dispatch(
            usersActions.setCurrentUser({
              user: decodedUser,
              session: sbSession.data.session,
              permissions: (decodedUser.user_permissions?.split(',') ??
                []) as PermissionTypes[],
              role: decodedUser.user_role
            })
          );
        }
      };

      verifySession();

      setInitTriggered(true);
    }
  }, [initTriggered, dispatch]);

  return <></>;
};

export default Session;
