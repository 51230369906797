import { Button, Tooltip } from '@arco-design/web-react';
import {
  IconDoubleLeft,
  IconInfoCircle,
  IconRefresh
} from '@arco-design/web-react/icon';
import clsx from 'clsx';
import { useMemo } from 'react';
import {
  ButtonShape,
  ButtonSize,
  ButtonType
} from '../../../constants/arcoEnums';
import { environmentVariables } from '../../../constants/environment';
import { HEX_IFRAME_BASE_HOSTNAME } from '../../../constants/web';
import useLocale from '../../../hooks/useLocale';
import { layoutActions } from '../../../redux/slices/layout.slice';
import { useAppDispatch } from '../../../redux/store';
import { HexProject } from '../../../typings/hex.model';
import locale from './locale/locale';
import { ModelsPageSteps } from './Models.page';
import './style/hex_overwrites.less';

type Props = {
  chosenProject: HexProject;
  setPageStep: (step: ModelsPageSteps) => void;
  setChosenProject: (project: HexProject | null) => void;
  invokeRefresh: () => void;
};

const ModelsProjectDisplay = ({
  chosenProject,
  setPageStep,
  setChosenProject,
  invokeRefresh
}: Props) => {
  const t = useLocale(locale);
  const dispatch = useAppDispatch();

  const composedIframeSrc = useMemo(
    () =>
      `${HEX_IFRAME_BASE_HOSTNAME}/${environmentVariables.VITE_HEX_WORKSPACE_ID}/app/${chosenProject.id}/latest?embedded=true`,
    [chosenProject]
  );

  return (
    <div className="relative w-full h-full">
      <div className="absolute top-12 right-4 flex flex-col gap-y-3">
        <div className="flex flex-row gap-x-3">
          <Tooltip
            trigger="hover"
            content={t['models.hexProjects.table.loginRefresh']}
          >
            <Button
              size={ButtonSize.SMALL}
              type={ButtonType.SECONDARY}
              shape={ButtonShape.ROUND}
              icon={<IconInfoCircle />}
            />
          </Tooltip>
          <Tooltip
            trigger="hover"
            content={t['models.hexProjects.table.refresh']}
          >
            <Button
              size={ButtonSize.SMALL}
              type={ButtonType.PRIMARY}
              shape={ButtonShape.ROUND}
              icon={<IconRefresh />}
              onClick={() => {
                invokeRefresh();
              }}
            />
          </Tooltip>
        </div>
        <div className="flex flex-row justify-end">
          <Tooltip
            trigger="hover"
            content={t['models.hexProjects.table.backToList']}
          >
            <Button
              size={ButtonSize.SMALL}
              type={ButtonType.PRIMARY}
              shape={ButtonShape.ROUND}
              icon={<IconDoubleLeft />}
              onClick={() => {
                dispatch(layoutActions.setFullSizeContent(false));
                setPageStep(ModelsPageSteps.PROJECTS_CHOICE);
                setChosenProject(null);
              }}
            />
          </Tooltip>
        </div>
      </div>

      <div
        className={clsx('hex-embed', 'flex flex-col w-full')}
        style={{ height: 'calc(100vh - 60px)' }}
      >
        <iframe className="flex-1" src={composedIframeSrc}></iframe>
      </div>
    </div>
  );
};

export default ModelsProjectDisplay;
