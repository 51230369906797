import { createApi } from '@reduxjs/toolkit/query/react';
import { API_OPERATIONAL_SUBPATH } from '../../constants/web';
import { ConversionService } from '../../services/utils/conversion.service';
import { HealthResponse } from '../../typings/web.model';
import { axiosBaseQuery } from './baseClients';

export const operationalApi = createApi({
  reducerPath: 'operationalApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/${API_OPERATIONAL_SUBPATH}`
  }),
  tagTypes: ['Health'],
  endpoints: (builder) => ({
    getHealth: builder.query<HealthResponse, null>({
      query: () => ({ url: '/health', method: 'GET' }),
      providesTags: ['Health'],
      // * incoming type is snake_case
      transformResponse: (response: HealthResponse) =>
        new ConversionService<HealthResponse>().toCamelCase(response)
    })
  })
});

export const { useGetHealthQuery } = operationalApi;
