const i18n = {
  'en-US': {
    'invitations.tabs.users': 'Users',
    'invitations.tabs.invite': 'Invite',
    'invitations.form.email.errMsg': 'Username cannot be empty',
    'invitations.form.email.errEmailMsg': 'Incorrect email format',
    'invitations.form.email.placeholder': 'Type user email',
    'invitations.form.invite': 'Invite',
    'invitations.subtitle': 'Invite users to CVector Energy via email'
  }
};

export default i18n;
