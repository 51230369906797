import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_VISIBLE_PROJECTS } from '../../constants/hex';
import { AppRootState } from '../store';

interface HexState {
  visibleProjectIds: string[];
}

const initialState: HexState = {
  visibleProjectIds: DEFAULT_VISIBLE_PROJECTS
};

const hexSlice = createSlice({
  name: 'hex',
  initialState,
  reducers: {
    setVisibleProjectIds: (
      state: HexState,
      action: PayloadAction<string[]>
    ) => {
      state.visibleProjectIds = action.payload;
    }
  }
});

export const hexActions = {
  setVisibleProjectIds: hexSlice.actions.setVisibleProjectIds
};

export const hexSelectors = {
  selectSetVisibleProjectIds: (state: AppRootState) =>
    state.hex.visibleProjectIds
};

export const hexReducer = hexSlice.reducer;
