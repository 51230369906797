import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { layoutSelectors } from '../../redux/slices/layout.slice';
import { useAppSelector } from '../../redux/store';

type Props = {
  title: string;
};

const ContentPage = ({ children, title }: PropsWithChildren<Props>) => {
  const fullSizeContent = useAppSelector(layoutSelectors.selectFullSizeContent);

  return (
    <>
      {!fullSizeContent && <h5 className="mb-5 ps-5">{title}</h5>}
      <div
        className={clsx('flex flex-col')}
        style={{
          minHeight: fullSizeContent
            ? 'calc(100vh - 60px)'
            : 'calc(100vh - 200px)'
        }}
      >
        {children}
      </div>
    </>
  );
};

export default ContentPage;
