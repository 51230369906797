import { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {
  GroupedShortMockSensorReadingState,
  sensorReadingsSelectors
} from '../../../redux/slices/sensorReadings.slice';
import { useAppSelector } from '../../../redux/store';
import styles from './style/trends.module.less';

type Props = {
  tagNames: string[];
};

type ChartData = {
  timestamp: string;
  [sensorName: string]: any;
};

const strokeColors = [
  '#8884d8',
  '#82ca9d',
  '#e71c1c',
  '#df7621',
  '#618dff',
  '#4b74ee',
  '#375cd3',
  '#2443b9',
  '#142a9f',
  '#090686',
  '#04036c',
  '#000053'
];

const TrendsMockChart = ({ tagNames }: Props) => {
  const [data, setData] = useState<ChartData[]>([]);
  const groupedReadings: GroupedShortMockSensorReadingState[] = useAppSelector(
    sensorReadingsSelectors.selectShortMockSensorReadings
  );

  useEffect(() => {
    const transformedReadings: ChartData[] = groupedReadings
      .map((gr) => {
        return gr.readings.map((r) => ({
          timestamp: r.tagTransmittedAt,
          [r.tagName]: r.tagValue
        }));
      })
      .flat();
    setData(transformedReadings);
  }, [groupedReadings]);

  return (
    <div className={styles['chart-container']}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={800}
          height={500}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis />
          <Tooltip />
          <Legend />
          {tagNames.map((tn, idx) => (
            <Line
              key={`${tn}-${idx}`}
              type="monotone"
              isAnimationActive={false}
              dataKey={tn}
              stroke={strokeColors[idx]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TrendsMockChart;
