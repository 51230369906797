import ResultCard from '../../components/ResultCard/ResultCard';
import useLocale from '../../hooks/useLocale';
import localeFile from './locale/locale';

type Props = {};

const Forbidden = (_props: Props) => {
  const t = useLocale(localeFile);

  return (
    <ResultCard resultStatus={'403'} subtitle={t['forbidden.description']} />
  );
};

export default Forbidden;
