import clsx from 'clsx';
import BlueFullLogoSvg from '../../assets/logos/blue_full_logo.svg?react';
import BlueSmallLogoSvg from '../../assets/logos/blue_small_logo.svg?react';
import RoundBadgeLogoSvg from '../../assets/logos/round_badge_logo.svg?react';
import SquareBadgeLogoSvg from '../../assets/logos/square_badge_logo.svg?react';
import { LogoVariants } from '../../constants/logo';

type Props = {
  variant:
    | LogoVariants.BLUE_FULL_LOGO
    | LogoVariants.ROUND_BADGE_LOGO
    | LogoVariants.SQUARE_BADGE_LOGO
    | LogoVariants.BLUE_SMALL_LOGO;
  heightClass?: string;
  widthClass?: string;
  className?: string;
};

const Logo = ({
  className,
  variant,
  heightClass = 'h-auto',
  widthClass = 'w-auto'
}: Props) => {
  const renderLogo = (variant: LogoVariants) => {
    switch (variant) {
      case LogoVariants.BLUE_FULL_LOGO:
        return <BlueFullLogoSvg className={clsx(heightClass, widthClass)} />;
      case LogoVariants.ROUND_BADGE_LOGO:
        return <RoundBadgeLogoSvg className={clsx(heightClass, widthClass)} />;
      case LogoVariants.SQUARE_BADGE_LOGO:
        return <SquareBadgeLogoSvg className={clsx(heightClass, widthClass)} />;
      case LogoVariants.BLUE_SMALL_LOGO:
        return <BlueSmallLogoSvg className={clsx(heightClass, widthClass)} />;
    }
  };

  return <div className={clsx(className, 'w-full')}>{renderLogo(variant)}</div>;
};

export default Logo;
