export enum ViteEnvironment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}
export enum GhEnvironment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}
export interface AppConfig {
  VITE_SUPABASE_HOSTNAME: string;
  VITE_SUPABASE_KEY: string;
  VITE_API_HOSTNAME: string;
  VITE_GH_ENV: GhEnvironment;
  VITE_BASE_PATH: string;
  VITE_HEX_WORKSPACE_ID: string;
  MODE: ViteEnvironment;
}
export enum AppConfigKeys {
  VITE_SUPABASE_HOSTNAME = 'VITE_SUPABASE_HOSTNAME',
  VITE_SUPABASE_KEY = 'VITE_SUPABASE_KEY',
  VITE_API_HOSTNAME = 'VITE_API_HOSTNAME',
  VITE_GH_ENV = 'VITE_GH_ENV',
  VITE_BASE_PATH = 'VITE_BASE_PATH',
  VITE_HEX_WORKSPACE_ID = 'VITE_HEX_WORKSPACE_ID'
}

export const environmentVariables: AppConfig = import.meta
  .env as unknown as AppConfig;
