import { v4 as uuid4 } from 'uuid';

export class GenerationService {
  static generateRandomNum: (min: number, max: number) => number = (
    min,
    max
  ) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  static generateUUID: () => string = () => uuid4();
}
