import { ReactNode } from 'react';
import { PermissionTypes } from '../../../constants/permissions';
import { PermissionService } from '../../../services/utils/permission.service';

type Props = {
  children: (allowed: boolean) => ReactNode;
  requiredPermissions: Set<PermissionTypes>;
  userPermissions: Set<PermissionTypes>;
};

/*
  Higher-Order Component for permission-based rendering of either the wrapped component or a permission denied component

  Usage:
  ```
    <Can
      requiredPermissions={new Set(["dash.read"])}
      userPermissions={userPermissions}
    >
      {(allowed) =>
        allowed ? <div>Dashboard Content</div> : <div>Access Denied</div>
      }
    </Can>
  ```
*/

const Can = ({ children, requiredPermissions, userPermissions }: Props) => {
  const allowed = PermissionService.hasPermission(
    requiredPermissions,
    userPermissions
  );
  return <>{children(allowed)}</>;
};

export default Can;
