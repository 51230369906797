import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavigationRoutes } from '../../../constants/routes';
import { usersSelectors } from '../../../redux/slices/users.slice';
import { useAppSelector } from '../../../redux/store';
import { AuthService } from '../../../services/data/auth.data.service';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const RequireAuth = ({ children }: Props) => {
  const currentUser = useAppSelector(usersSelectors.selectCurrentUser);
  const navigate = useNavigate();
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    const verifySession = async () => {
      if (!currentUser?.user) {
        const authService = new AuthService();
        const result = await authService.getSession();
        if (result.data.session?.user) {
          setInit(true);
        } else {
          navigate(NavigationRoutes.LOGIN);
        }
      } else {
        setInit(true);
      }
    };

    verifySession();
  }, [currentUser, navigate]);

  return init && children;
};

export default RequireAuth;
