import { Button, Form, Input, Space } from '@arco-design/web-react';
import { IconLock, IconSubscribed } from '@arco-design/web-react/icon';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useUpdateCurrentUserPasswordMutation } from '../../communications/api/users.api';
import Logo from '../../components/Logo/Logo';
import { LogoVariants } from '../../constants/logo';
import { PermissionTypes } from '../../constants/permissions';
import { NavigationRoutes } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import { layoutActions } from '../../redux/slices/layout.slice';
import { usersActions } from '../../redux/slices/users.slice';
import { useAppDispatch } from '../../redux/store';
import { AuthService } from '../../services/data/auth.data.service';
import { SupabaseUser } from '../../typings/users.model';
import locale from './locale/locale';
import styles from './style/setup_password.module.less';

type Props = {};

const SetupPasswordForm = (_props: Props) => {
  const t = useLocale(locale);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [updatePassword, { error: updateError, isLoading: updateLoading }] =
    useUpdateCurrentUserPasswordMutation();

  const [form] = Form.useForm();

  const onSubmitClick = async () => {
    if (form) {
      form.validate().then(async (values) => {
        const updateResult = await updatePassword({
          password: values.password
        }).unwrap();
        const authService = new AuthService();
        const sbSession = await authService.getSession();
        if (sbSession.data.session?.access_token) {
          const decodedUser: SupabaseUser = jwtDecode<SupabaseUser>(
            sbSession.data.session.access_token
          );
          dispatch(
            usersActions.setCurrentUser({
              user: decodedUser,
              session: sbSession.data.session,
              permissions: (decodedUser.user_permissions?.split(',') ??
                []) as PermissionTypes[],
              role: decodedUser.user_role
            })
          );
        }
        dispatch(
          layoutActions.addSuccessToast(
            `User ${updateResult.email} password changed`
          )
        );
        dispatch(
          layoutActions.addInfoToast(`User ${updateResult.email} logged in`)
        );
        navigate(NavigationRoutes.VISUALIZATION_DASHBOARDS);
      });
    }
  };

  useEffect(() => {
    if (updateError) {
      dispatch(layoutActions.addErrorToast('Invite user error'));
    }
  }, [updateError, dispatch]);

  return (
    <div className={styles['setup-password-form-wrapper']}>
      <Logo variant={LogoVariants.BLUE_FULL_LOGO} className={styles['logo']} />
      <div className={styles['setup-password-form-title']}>
        {t['setupPassword.form.title']}
      </div>
      <Form
        form={form}
        autoComplete="off"
        className={styles['setup-password-form']}
        layout="vertical"
        initialValues={{ password: '', confirmPassword: '' }}
      >
        <Form.Item
          field="password"
          rules={[
            {
              required: true,
              message: t['setupPassword.form.password.errMsg']
            },
            {
              minLength: 8,
              message: t['setupPassword.form.password.minLength']
            }
          ]}
        >
          <Input.Password
            prefix={<IconLock />}
            placeholder={t['setupPassword.form.password.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="confirmPassword"
          rules={[
            {
              required: true,
              message: t['setupPassword.form.password.errMsg']
            },
            {
              minLength: 8,
              message: t['setupPassword.form.password.minLength']
            },
            {
              validator: (value, callback) => {
                if (value !== form.getFieldValue('password')) {
                  callback(t['setupPassword.form.password.notSame']);
                } else {
                  callback();
                }
              }
            }
          ]}
        >
          <Input.Password
            prefix={<IconSubscribed />}
            placeholder={t['setupPassword.form.confirmPassword.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <Button
            type="primary"
            long
            onClick={onSubmitClick}
            loading={updateLoading}
          >
            {t['setupPassword.form.reqSubmit']}
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default SetupPasswordForm;
