import clsx from 'clsx';
import queryString from 'query-string';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import LandingCarousel from '../../components/LandingCarousel/LandingCarousel';
import { NavigationRoutes } from '../../constants/routes';
import { StorageKeys } from '../../constants/storageKeys';
import { Themes } from '../../constants/themes';
import useStorage from '../../hooks/useStorage';
import { layoutActions } from '../../redux/slices/layout.slice';
import { useAppDispatch } from '../../redux/store';
import { AuthService } from '../../services/data/auth.data.service';
import { ThemeService } from '../../services/utils/theme.service';
import SetupPasswordForm from './SetupPasswordForm';
import styles from './style/setup_password.module.less';

type Props = {};

const SetupPassword = (_props: Props) => {
  const [storageTheme] = useStorage(StorageKeys.THEME);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useLayoutEffect(() => {
    document.body.setAttribute('arco-theme', Themes.LIGHT);

    return () => {
      if (storageTheme === Themes.DARK) {
        ThemeService.changeTheme(Themes.DARK);
      }
    };
  }, [storageTheme]);

  useEffect(() => {
    const verifySession = async () => {
      const authService = new AuthService();
      const sbSession = await authService.getSession();
      if (sbSession.data.session?.access_token) {
        const user = await authService.getUserInfo();
        if (!user) {
          dispatch(
            layoutActions.addInfoToast(`No user found in current session`)
          );
          navigate(NavigationRoutes.LOGIN);
        }
      }

      const parsedHash = queryString.parse(location.hash);

      if (!parsedHash?.refresh_token) {
        dispatch(
          layoutActions.addInfoToast(
            `No access token found in redirection link`
          )
        );
        navigate(NavigationRoutes.LOGIN);
      } else {
        try {
          authService.refreshSessionWithToken(
            parsedHash?.refresh_token as string
          );
        } catch (_error) {
          dispatch(layoutActions.addErrorToast(`Error refreshing session`));
          navigate(NavigationRoutes.LOGIN);
        }
      }
    };

    verifySession();
  }, [navigate, dispatch, location]);

  return (
    <div className={clsx(styles.container, 'grid grid-cols-12')}>
      <div
        className={clsx(
          styles.banner,
          'z-10 hidden md:flex col-span-12 md:col-span-6 lg:col-span-4'
        )}
      >
        <div className={styles['banner-inner']}>
          <LandingCarousel />
        </div>
      </div>
      <div
        className={clsx(
          styles.content,
          'col-span-12 md:col-span-6 lg:col-span-8'
        )}
      >
        <div className={styles['content-inner']}>
          <SetupPasswordForm />
        </div>
      </div>
    </div>
  );
};

export default SetupPassword;
