import { Button, Card, Table, TableColumnProps } from '@arco-design/web-react';
import { IconDown, IconExport, IconRight } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  ButtonShape,
  ButtonSize,
  ButtonType
} from '../../../constants/arcoEnums';
import useLocale from '../../../hooks/useLocale';
import { layoutActions } from '../../../redux/slices/layout.slice';
import { useAppDispatch } from '../../../redux/store';
import { HexProject } from '../../../typings/hex.model';
import locale from './locale/locale';
import { ModelsPageSteps } from './Models.page';
import styles from './style/models.module.less';

type Props = {
  projects: HexProject[] | undefined;
  visibleIds: string[];
  loading: boolean;
  setPageStep: (step: ModelsPageSteps) => void;
  setChosenProject: (project: HexProject) => void;
};

interface TableDataRecord {
  key: string;
  id: string;
  name: string;
  description: string;
  creator: string;
  createdAt: string;
  updatedAt: string;
}

const ModelsProjectsTable = ({
  projects,
  loading,
  visibleIds,
  setPageStep,
  setChosenProject
}: Props) => {
  const t = useLocale(locale);

  const dispatch = useAppDispatch();

  const filteredProjects = useMemo(
    () => (projects ?? []).filter((p) => visibleIds.includes(p.id)),
    [projects, visibleIds]
  );

  const handleActionButtonClick = (record: TableDataRecord) => {
    const foundProject = filteredProjects?.find((p) => p.id === record.id);
    if (foundProject) {
      dispatch(layoutActions.setFullSizeContent(true));
      setChosenProject(foundProject);
      setPageStep(ModelsPageSteps.PROJECT_DISPLAY);
    }
  };

  const columns: TableColumnProps<TableDataRecord>[] = [
    {
      title: t['models.hexProjects.table.projectName'],
      dataIndex: 'name'
    },
    {
      title: t['models.hexProjects.table.creator'],
      dataIndex: 'creator'
    },
    {
      title: t['models.hexProjects.table.createdAt'],
      dataIndex: 'createdAt'
    },
    {
      title: t['models.hexProjects.table.updatedAt'],
      dataIndex: 'updatedAt'
    },
    {
      title: t['models.hexProjects.table.action'],
      dataIndex: 'action',
      render: (_col, record) => (
        <Button
          onClick={() => handleActionButtonClick(record)}
          shape={ButtonShape.ROUND}
          size={ButtonSize.SMALL}
          icon={<IconExport />}
          type={ButtonType.PRIMARY}
        >
          {t['models.hexProjects.table.load']}
        </Button>
      )
    }
  ];

  const data: TableDataRecord[] = filteredProjects.map((project) => ({
    key: project.id,
    id: project.id,
    name: project.title,
    description:
      project.description ?? t['models.hexProjects.table.noDescription'],
    creator: project.creator.email,
    createdAt: dayjs(project.lastEditedAt).format('DD-MM-YYYY'),
    updatedAt: project.lastEditedAt
      ? dayjs(project.lastEditedAt).format('DD-MM-YYYY')
      : t['models.hexProjects.table.noData']
  }));

  return (
    <Card>
      <div className={styles.subtitle}>
        {t['models.hexProjects.displaySubtitle']}
      </div>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        border
        expandedRowRender={(record: TableDataRecord) =>
          record.description ?? t['models.hexProjects.table.noDescription']
        }
        expandProps={{
          icon: ({ expanded, record, ...restProps }) =>
            expanded ? (
              <button {...restProps}>
                <IconDown />
              </button>
            ) : (
              <button {...restProps}>
                <IconRight />
              </button>
            ),
          width: 60,
          columnTitle: t['models.hexProjects.table.expand']
        }}
      />
    </Card>
  );
};

export default ModelsProjectsTable;
