import { Table, TableColumnProps } from '@arco-design/web-react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useFetchUsersAdminQuery } from '../../../communications/api/users.api';
import { layoutActions } from '../../../redux/slices/layout.slice';
import { useAppDispatch } from '../../../redux/store';

type Props = {};

enum UserListKeys {
  ID = 'id',
  EMAIL = 'email',
  PHONE = 'phone',
  CONFIRMED = 'confirmed',
  CREATED_AT = 'createdAt'
}

type ColumnData = {
  key: string;
  [UserListKeys.ID]: string;
  [UserListKeys.EMAIL]: string;
  [UserListKeys.PHONE]: string;
  [UserListKeys.CONFIRMED]: string;
  [UserListKeys.CREATED_AT]: string;
};

const InvitationsUsers = (_props: Props) => {
  const dispatch = useAppDispatch();

  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading
  } = useFetchUsersAdminQuery(null);

  const [columnData, setColumnData] = useState<ColumnData[]>([]);

  useEffect(() => {
    if (usersError) {
      dispatch(layoutActions.addErrorToast('Users fetch admin error'));
    }
  }, [usersError, dispatch]);

  useEffect(() => {
    const columnData: ColumnData[] =
      usersData?.users.map((user) => ({
        key: user.id,
        [UserListKeys.ID]: user.id,
        [UserListKeys.EMAIL]: user.email ?? '',
        [UserListKeys.PHONE]: isEmpty(user.phone)
          ? 'No phone'
          : (user.phone as string),
        [UserListKeys.CONFIRMED]: user.confirmed_at ? 'Yes' : 'No',
        [UserListKeys.CREATED_AT]: dayjs(user.created_at).format('DD/MM/YYYY')
      })) ?? [];
    setColumnData(columnData);
  }, [usersData]);

  const columns: TableColumnProps[] = [
    {
      title: 'Id',
      dataIndex: UserListKeys.ID
    },
    {
      title: 'Email',
      dataIndex: UserListKeys.EMAIL
    },
    {
      title: 'Phone',
      dataIndex: UserListKeys.PHONE
    },
    {
      title: 'Confirmed',
      dataIndex: UserListKeys.CONFIRMED
    },
    {
      title: 'Created At',
      dataIndex: UserListKeys.CREATED_AT
    }
  ];

  return (
    <div>
      <Table columns={columns} data={columnData} loading={usersLoading} />;
    </div>
  );
};

export default InvitationsUsers;
