import { createApi } from '@reduxjs/toolkit/query/react';
import { API_READINGS_SUBPATH } from '../../constants/web';
import { ConversionService } from '../../services/utils/conversion.service';
import { AvailableTagsResponse } from '../../typings/sensorReadings.model';
import { axiosBaseQuery } from './baseClients';

export const sensorReadingsApi = createApi({
  reducerPath: 'sensorReadingApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/${API_READINGS_SUBPATH}`
  }),
  tagTypes: ['SensorTags'],
  endpoints: (builder) => ({
    getAvailableTags: builder.query<AvailableTagsResponse, null>({
      query: () => ({ url: '/available_sensors', method: 'GET' }),
      providesTags: ['SensorTags'],
      // * incoming type is snake_case
      transformResponse: (response: AvailableTagsResponse) =>
        new ConversionService<AvailableTagsResponse>().toCamelCase(response)
    })
  })
});

export const { useGetAvailableTagsQuery } = sensorReadingsApi;
