import ResultCard from '../ResultCard/ResultCard';
import useLocale from '../../hooks/useLocale';
import localeFile from './locale/locale';
import { PropsWithChildren } from 'react';

type Props = {};

const UnderConstruction = (props: PropsWithChildren<Props>) => {
  const t = useLocale(localeFile);

  return (
    <ResultCard
      resultStatus={'404'}
      subtitle={t['construction.description']}
      children={props.children}
    />
  );
};

export default UnderConstruction;
