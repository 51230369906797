import { Divider, InputNumber, Switch } from '@arco-design/web-react';
import { ReactNode } from 'react';
import useLocale from '../../hooks/useLocale';
import {
  layoutActions,
  layoutSelectors
} from '../../redux/slices/layout.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import locale from './locale/locale';
import styles from './style/block.module.less';

export interface BlockProps {
  title?: ReactNode;
  options?: { name: string; value: string; type?: 'switch' | 'number' }[];
  children?: ReactNode;
}

export default function SettingsBlock(props: BlockProps) {
  const { title, options, children } = props;
  const t = useLocale(locale);
  const settings = useAppSelector(layoutSelectors.selectSettings);
  const dispatch = useAppDispatch();

  return (
    <div className={styles.block}>
      <h5 className={styles.title}>{title}</h5>
      {options &&
        options.map((option) => {
          const type = option.type || 'switch';

          return (
            <div className={styles['switch-wrapper']} key={option.value}>
              <span>{t[option.name]}</span>
              {type === 'switch' && (
                <Switch
                  size="small"
                  checked={!!settings[option.value as keyof typeof settings]}
                  onChange={(checked) => {
                    const newSettings = {
                      ...settings,
                      [option.value]: checked
                    };
                    dispatch(layoutActions.setSettings(newSettings));
                    if (checked && option.value === 'colorWeak') {
                      document.body.style.filter = 'invert(80%)';
                    }
                    if (!checked && option.value === 'colorWeak') {
                      document.body.style.filter = 'none';
                    }
                  }}
                />
              )}
              {type === 'number' && (
                <InputNumber
                  className={styles['width-input']}
                  size="small"
                  value={settings.menuWidth}
                  onChange={(value) => {
                    const newSettings = {
                      ...settings,
                      [option.value]: value
                    };
                    dispatch(layoutActions.setSettings(newSettings));
                  }}
                />
              )}
            </div>
          );
        })}
      {children}
      <Divider />
    </div>
  );
}
