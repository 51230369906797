import {
  Avatar,
  Descriptions,
  Link,
  Skeleton,
  Tag,
  Upload
} from '@arco-design/web-react';
import { UploadItem } from '@arco-design/web-react/es/Upload';
import { IconCamera, IconLoading, IconPlus } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useGetObjectDetailsQuery } from '../../../communications/api/storage.api';
import { useUpdateUserAvatarMutation } from '../../../communications/api/users.api';
import { FileTypes } from '../../../constants/files';
import useLocale from '../../../hooks/useLocale';
import { layoutActions } from '../../../redux/slices/layout.slice';
import { useAppDispatch } from '../../../redux/store';
import { UserDetails } from '../../../typings/userDetails.model';
import { LoggedInUser } from '../../../typings/users.model';
import locale from './locale/locale';
import styles from './style/user-settings.module.less';

interface Props {
  currentUser: LoggedInUser;
  userDetails: UserDetails;
  loading?: boolean;
}

// TODO - add translations

export default function UserSettingsDisplay({
  currentUser,
  userDetails,
  loading
}: Props) {
  const t = useLocale(locale);

  const [avatar, setAvatar] = useState<string>('');
  const [changeAvatar, setChangeAvatar] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [updateAvatar, { isLoading: imageLoading }] =
    useUpdateUserAvatarMutation();

  const { data: fileDetails, isLoading: loadingFileDetails } =
    useGetObjectDetailsQuery(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      { fileObjectId: userDetails?.avatarId! },
      {
        skip: !userDetails?.avatarId
      }
    );

  useEffect(() => {
    if (fileDetails?.publicPath) {
      setAvatar(fileDetails.publicPath);
    }
  }, [fileDetails]);

  const onAvatarChange = async (_fileList: UploadItem[], file: UploadItem) => {
    if (file.originFile && !changeAvatar) {
      setChangeAvatar(true);
      if (!file.originFile.type.startsWith('image')) {
        dispatch(layoutActions.addErrorToast('Only image files are allowed'));
        setChangeAvatar(false);
        return;
      }
      await updateAvatar({
        userDetailsId: userDetails.id,
        file: file.originFile,
        fileType: FileTypes.AVATAR,
        filename: file.originFile.name
      }).unwrap();
      dispatch(layoutActions.addSuccessToast('Avatar updated'));
      setAvatar(file.originFile ? URL.createObjectURL(file.originFile) : '');
      setChangeAvatar(false);
    }
  };

  const composeVerifiedTag = (success: boolean) => (
    <Tag color={success ? 'green' : 'red'} className={styles['verified-tag']}>
      {success
        ? t['userSetting.value.verified']
        : t['userSetting.value.notVerified']}
    </Tag>
  );

  const loadingNode = <Skeleton text={{ rows: 1 }} animation />;

  return (
    <div className={styles['info-wrapper']}>
      <Upload
        disabled={userDetails ? false : true}
        showUploadList={false}
        onChange={(fileList: UploadItem[], file: UploadItem) =>
          onAvatarChange(fileList, file)
        }
      >
        {loading || imageLoading || loadingFileDetails ? (
          <Avatar
            size={100}
            triggerIcon={<IconCamera />}
            className={styles['info-avatar']}
          >
            <IconLoading />
          </Avatar>
        ) : (
          <Avatar
            size={100}
            triggerIcon={<IconCamera />}
            className={styles['info-avatar']}
            onClick={() => {
              if (!userDetails) {
                dispatch(
                  layoutActions.addInfoToast(
                    'In order to change avatar, please save user details first'
                  )
                );
              }
            }}
          >
            {avatar ? <img src={avatar} /> : <IconPlus />}
          </Avatar>
        )}
      </Upload>
      <Descriptions
        className={styles['info-content']}
        column={2}
        colon="："
        labelStyle={{ textAlign: 'right' }}
        data={[
          {
            label: t['userSetting.info.email'],
            value: loading ? loadingNode : currentUser.user.email
          },
          {
            label: t['userSetting.label.emailVerified'],
            value: loading ? (
              loadingNode
            ) : (
              <span>
                {currentUser.user.email_confirmed_at
                  ? composeVerifiedTag(true)
                  : composeVerifiedTag(false)}
              </span>
            )
          },
          {
            label: t['userSetting.label.accountId'],
            value: loading ? loadingNode : currentUser.user.id
          },
          {
            label: t['userSetting.label.phoneNumber'],
            value: loading ? (
              loadingNode
            ) : (
              <span>
                {currentUser.user.phone
                  ? currentUser.user.phone
                  : t['userSetting.value.noPhone']}
              </span>
            )
          },
          {
            label: t['userSetting.label.registrationTime'],
            value: loading
              ? loadingNode
              : dayjs(currentUser.user.confirmed_at).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
          },
          {
            label: t['userSetting.label.avatarId'],
            value: loading
              ? loadingNode
              : userDetails?.avatarId
                ? userDetails?.avatarId
                : t['userSetting.value.noAvatar']
          },
          {
            label: t['userSetting.label.avatarPath'],
            value: loading ? (
              loadingNode
            ) : fileDetails?.publicPath ? (
              <Link href={fileDetails?.publicPath}>Link</Link>
            ) : (
              t['userSetting.value.noAvatar']
            )
          },
          {
            label: t['userSetting.label.firstName'],
            value: loading
              ? loadingNode
              : userDetails?.firstName
                ? userDetails?.firstName
                : t['userSetting.value.noData']
          },
          {
            label: t['userSetting.label.lastName'],
            value: loading
              ? loadingNode
              : userDetails?.lastName
                ? userDetails?.lastName
                : t['userSetting.value.noData']
          },
          {
            label: t['userSetting.label.birthDate'],
            value: loading
              ? loadingNode
              : userDetails?.birthDate
                ? dayjs(userDetails?.birthDate).format('DD-MM-YYYY')
                : t['userSetting.value.noData']
          }
        ]}
      ></Descriptions>
    </div>
  );
}
