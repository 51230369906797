import { Breadcrumb, Layout, Menu } from '@arco-design/web-react';
import {
  IconBook,
  IconDashboard,
  IconMenuFold,
  IconMenuUnfold,
  IconRobot,
  IconUser
} from '@arco-design/web-react/icon';
import clsx from 'clsx';
import { isNil } from 'lodash';
import queryString from 'query-string';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import Navbar from '../../components/Navbar/Navbar';
import {
  IRoute,
  NavigationRoutes,
  ParentRoutes,
  layoutRoutes,
  matchRoutes
} from '../../constants/routes';
import { StorageKeys } from '../../constants/storageKeys';
import { Themes } from '../../constants/themes';
import useLocale from '../../hooks/useLocale';
import usePermissions from '../../hooks/usePermissions';
import useStorage from '../../hooks/useStorage';
import {
  layoutActions,
  layoutSelectors
} from '../../redux/slices/layout.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { PermissionService } from '../../services/utils/permission.service';
import { ThemeService } from '../../services/utils/theme.service';
import localeFile from './locale/locale';
import styles from './style/layout.module.less';

type Props = {};

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const Sider = Layout.Sider;
const Content = Layout.Content;

const getIconFromKey = (key: NavigationRoutes | ParentRoutes) => {
  switch (key) {
    case ParentRoutes.VISUALIZATION:
      return <IconDashboard className={styles.icon} />;
    case ParentRoutes.ANALYSIS:
      return <IconRobot className={styles.icon} />;
    case ParentRoutes.DATA:
      return <IconBook className={styles.icon} />;
    case ParentRoutes.USER:
      return <IconUser className={styles.icon} />;
    default:
      return <div className={styles['icon-empty']} />;
  }
};

const BaseLayout = (_props: Props) => {
  const settings = useAppSelector(layoutSelectors.selectSettings);
  const loaderActive = useAppSelector(layoutSelectors.selectGlobalLoaderActive);
  const fullSizeContent = useAppSelector(layoutSelectors.selectFullSizeContent);

  const { uniquePermissions } = usePermissions();
  const dispatch = useAppDispatch();

  const t = useLocale(localeFile);
  const [storageTheme] = useStorage(StorageKeys.THEME);

  const location = useLocation();
  const navigate = useNavigate();

  const currentComponent: NavigationRoutes = useMemo(
    () =>
      `/${queryString.parseUrl(location.pathname).url.slice(1)}` as NavigationRoutes,
    [location]
  );
  const currentComponentParent: ParentRoutes = useMemo(
    () => matchRoutes(layoutRoutes, currentComponent).parent?.key,
    [currentComponent]
  ) as ParentRoutes;

  const defaultSelectedKeys = [
    currentComponent || NavigationRoutes.VISUALIZATION_DASHBOARDS
  ];
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] =
    useState<string[]>(defaultSelectedKeys);
  const [openKeys, setOpenKeys] = useState<(NavigationRoutes | ParentRoutes)[]>(
    [currentComponentParent]
  );

  const navbarHeight = 60;
  const menuWidth = collapsed ? 48 : settings.menuWidth;
  const showNavbar = settings.navbar;
  const showMenu = settings.menu;
  const showFooter = settings.footer;

  useLayoutEffect(() => {
    if (storageTheme) {
      dispatch(layoutActions.setTheme(storageTheme as Themes));
      ThemeService.changeTheme(storageTheme as Themes);
    }
  }, [dispatch, storageTheme]);

  useEffect(() => {
    if (currentComponent !== selectedKeys[0]) {
      setSelectedKeys([currentComponent]);
      if (!openKeys.includes(currentComponentParent)) {
        setOpenKeys([...openKeys, currentComponentParent]);
      }
    }
  }, [currentComponent, selectedKeys, openKeys, currentComponentParent]);

  const composeSubmenuItems = () => {
    return layoutRoutes.map((submenuItem) => {
      const iconDom = getIconFromKey(submenuItem.key);
      const titleDom = (
        <>
          {iconDom} {t[submenuItem.name] || submenuItem.name}
        </>
      );
      return (
        <SubMenu
          key={submenuItem.key}
          title={titleDom}
          style={{ paddingRight: collapsed ? '10px' : '0' }}
        >
          {composeMenuItems(
            submenuItem.children,
            submenuItem.key as ParentRoutes
          )}
        </SubMenu>
      );
    });
  };

  const composeMenuItems = (menuItems: IRoute[], _parentKey: ParentRoutes) => {
    const menuItemsToRender = menuItems.map((menuItem) => {
      const iconDom = getIconFromKey(menuItem.key);
      const titleDom = (
        <>
          {iconDom} {t[menuItem.name] || menuItem.name}
        </>
      );
      const permissionCheck = PermissionService.hasPermission(
        menuItem.requiredPermissions,
        uniquePermissions
      );
      if (permissionCheck) {
        return (
          <MenuItem key={menuItem.key} disabled={!!menuItem.disabled}>
            {titleDom}
          </MenuItem>
        );
      }
    });
    if (menuItemsToRender.filter((i) => !isNil(i)).length === 0) {
      return (
        <MenuItem key="empty" disabled>
          <>
            <div className={styles['icon-empty']} /> {t['menu.empty']}
          </>
        </MenuItem>
      );
    }
    return menuItemsToRender;
  };

  const renderedBreadcrumbs = useMemo(() => {
    const matched = matchRoutes(layoutRoutes, currentComponent);
    if (matched.parent && matched.child) {
      return (
        <Breadcrumb>
          <Breadcrumb.Item key={matched.parent.key}>
            {t[matched.parent.name] ?? matched.parent.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item key={matched.child.key}>
            {t[matched.child.name] ?? matched.child.name}
          </Breadcrumb.Item>
        </Breadcrumb>
      );
    }
    return null;
  }, [currentComponent, t]);

  const onClickMenuItem = (key: string) => {
    setSelectedKeys([key]);

    const iKey: NavigationRoutes = key as NavigationRoutes;
    const matchedRoutes = matchRoutes(layoutRoutes, iKey);

    if (matchedRoutes.child) {
      navigate((matchedRoutes.child as IRoute).key);
    }
  };

  function toggleCollapse() {
    setCollapsed((collapsed) => !collapsed);
  }

  const paddingLeft = showMenu ? { paddingLeft: menuWidth } : {};
  const paddingTop = showNavbar ? { paddingTop: navbarHeight } : {};
  const paddingStyle = { ...paddingLeft, ...paddingTop };

  return (
    <Layout className={styles.layout}>
      <div
        className={clsx(styles['layout-navbar'], {
          [styles['layout-navbar-hidden']]: !showNavbar
        })}
      >
        <Navbar show={showNavbar} />
      </div>
      <Layout>
        {showMenu && (
          <Sider
            className={styles['layout-sider']}
            width={menuWidth}
            collapsed={collapsed}
            onCollapse={setCollapsed}
            trigger={null}
            collapsible
            breakpoint="xl"
            style={paddingTop}
          >
            <div className={styles['menu-wrapper']}>
              <Menu
                collapse={collapsed}
                onClickMenuItem={onClickMenuItem}
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                onClickSubMenu={(_, openKeys) => {
                  setOpenKeys(openKeys as NavigationRoutes[]);
                }}
              >
                {composeSubmenuItems()}
              </Menu>
            </div>
            <div className={styles['collapse-btn']} onClick={toggleCollapse}>
              {collapsed ? <IconMenuUnfold /> : <IconMenuFold />}
            </div>
          </Sider>
        )}
        <Layout className={styles['layout-content']} style={paddingStyle}>
          <div style={{ padding: fullSizeContent ? '0' : '16px 20px 0' }}>
            {loaderActive && <Loader className={styles['loader']} useMask />}
            {!fullSizeContent && (
              <div className={styles['layout-breadcrumb']}>
                {renderedBreadcrumbs}
              </div>
            )}
            <Content>
              <Outlet />
              {showFooter && !fullSizeContent && <Footer />}
            </Content>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
