import {
  Button,
  DatePicker,
  Form,
  Input,
  Skeleton,
  Space
} from '@arco-design/web-react';
import omit from 'lodash/omit';
import { useEffect } from 'react';
import {
  useCreateUserDetailsMutation,
  useLazyGetUserDetailsQuery,
  usersApi,
  useUpdateUserDetailsMutation
} from '../../../communications/api/users.api';
import useLocale from '../../../hooks/useLocale';
import { layoutActions } from '../../../redux/slices/layout.slice';
import { useAppDispatch } from '../../../redux/store';
import {
  CreateUserDetails,
  UserDetails
} from '../../../typings/userDetails.model';
import { LoggedInUser } from '../../../typings/users.model';
import locale from './locale/locale';
import styles from './style/user-settings.module.less';
import dayjs from 'dayjs';
import { GenerationService } from '../../../services/utils/generation.service';

interface Props {
  currentUser: LoggedInUser;
  userDetails: UserDetails;
  loading?: boolean;
}

// TODO - add translations

function UserSettingsForm({ currentUser, userDetails, loading }: Props) {
  const t = useLocale(locale);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [updateUserDetails, { error: _updateError, isLoading: updateLoading }] =
    useUpdateUserDetailsMutation();
  const [createUserDetails, { error: _createError, isLoading: createLoading }] =
    useCreateUserDetailsMutation();
  const [getUserDetails, { error: _loginError, isLoading: detailsLoading }] =
    useLazyGetUserDetailsQuery();

  useEffect(() => {
    if (userDetails) {
      form.setFieldsValue({
        email: currentUser.user.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        birthDate: userDetails.birthDate
      });
    }
  }, [userDetails]);

  const handleSave = async () => {
    try {
      await form.validate();
      if (userDetails) {
        await updateUserDetails({
          id: userDetails.id,
          details: omit<Partial<UserDetails>>(form.getFieldsValue(), ['email'])
        }).unwrap();
        usersApi.util.invalidateTags(['UserDetails']);
        dispatch(layoutActions.addSuccessToast('Details updated'));
      } else {
        const createPayload: CreateUserDetails = {
          ...omit<Partial<UserDetails>>(form.getFieldsValue(), ['email']),
          authId: currentUser.user.id,
          id: GenerationService.generateUUID()
        } as CreateUserDetails;
        await createUserDetails(createPayload).unwrap();
        getUserDetails({ id: currentUser.user.id });
        dispatch(layoutActions.addSuccessToast('User Details created'));
      }
    } catch (_) {
      // eslint-disable-next-line no-console
      console.log('userSettings save error');
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  const loadingNode = (rows = 1) => {
    return (
      <Skeleton
        text={{
          rows,
          width: new Array(rows).fill('100%')
        }}
        animation
      />
    );
  };

  return (
    <Form
      className={styles.form}
      form={form}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      initialValues={{
        email: currentUser.user.email,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        birthDate: userDetails?.birthDate,
        avatarId: userDetails?.avatarId
      }}
    >
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <Form.Item
            label={t['userSetting.info.email']}
            field="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: t['userSetting.info.email.placeholder']
              }
            ]}
            disabled
          >
            {loading ? (
              loadingNode()
            ) : (
              <Input placeholder={t['userSetting.info.email.placeholder']} />
            )}
          </Form.Item>
          <Form.Item
            label="First Name"
            field="firstName"
            rules={[
              {
                required: true,
                message: 'First Name is required'
              }
            ]}
          >
            {loading ? loadingNode() : <Input placeholder="First name" />}
          </Form.Item>
          <Form.Item
            label="Last Name"
            field="lastName"
            rules={[
              {
                required: true,
                message: 'Last Name is required'
              }
            ]}
          >
            {loading ? loadingNode() : <Input placeholder="Last name" />}
          </Form.Item>
          <Form.Item label="Birth Date" field="birthDate" rules={[]}>
            {loading ? (
              loadingNode()
            ) : (
              <DatePicker
                placeholder="Birth Date"
                style={{ width: '100%' }}
                disabledDate={(current) => current.isAfter(dayjs())}
              />
            )}
          </Form.Item>

          <Form.Item label=" ">
            <Space>
              <Button
                type="primary"
                onClick={handleSave}
                loading={updateLoading || createLoading || detailsLoading}
              >
                {t['userSetting.save']}
              </Button>
              <Button onClick={handleReset}>{t['userSetting.reset']}</Button>
            </Space>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default UserSettingsForm;
