import { useEffect } from 'react';
import { useGetHealthQuery } from '../../communications/api/operational.api';
import { layoutActions } from '../../redux/slices/layout.slice';
import { useAppDispatch } from '../../redux/store';

type Props = {};

const HealthCheck = (_props: Props) => {
  const dispatch = useAppDispatch();
  const { data: healthData, error: healthError } = useGetHealthQuery(null);

  useEffect(() => {
    if (healthData) {
      dispatch(layoutActions.setBackendActive(true));
    }
  }, [healthData, dispatch]);

  useEffect(() => {
    if (healthError) {
      dispatch(layoutActions.addErrorToast('Backend health check failed'));
    }
  }, [healthError, dispatch]);

  return <></>;
};

export default HealthCheck;
