export interface ApiResponseWrapper<T> {
  response: T;
}

export enum GenericResponseResult {
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

// TODO - verify if Python BE returns that
export interface ExceptionResponse {
  status: number;
  timestamp: string;
  message: string;
  details: string | object;
}

export enum WEBSOCKET_MESSAGE_TYPE {
  INITIAL_DATA = 'INITIAL_DATA',
  SEND_MESSAGE = 'SEND_MESSAGE',
  NEW_MESSAGE = 'NEW_MESSAGE'
}

export type UrlParamsType = Record<string, any>;

export interface HealthResponse {
  status: GenericResponseResult;
}

export type Try<SuccessType, ErrorType> = {
  data: SuccessType | null;
  error: ErrorType | null;
};

export interface SingleItemRequest {
  id: string;
}
