import { Card, Tabs } from '@arco-design/web-react';
import { useEffect, useState } from 'react';
import { useGetUserDetailsQuery } from '../../../communications/api/users.api';
import ContentPage from '../../../components/ContentPage/ContentPage';
import useLocale from '../../../hooks/useLocale';
import { layoutActions } from '../../../redux/slices/layout.slice';
import {
  usersActions,
  usersSelectors
} from '../../../redux/slices/users.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import locale from './locale/locale';
import styles from './style/user-settings.module.less';
import UserSettingsForm from './UserSettingForm';
import UserSettingsDisplay from './UserSettingsDisplay';

function UserInfo() {
  const t = useLocale(locale);

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(usersSelectors.selectCurrentUser);

  const {
    data: userDetails,
    error: detailsError,
    isLoading
  } = useGetUserDetailsQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    { id: currentUser?.user.id! },
    {
      skip: !currentUser
    }
  );

  useEffect(() => {
    if (userDetails) {
      dispatch(usersActions.setUserDetails(userDetails));
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (detailsError) {
      dispatch(layoutActions.addErrorToast('User details fetch error'));
    }
  }, [detailsError, dispatch]);

  const [activeTab, setActiveTab] = useState('basic');
  return (
    <ContentPage title="User Settings">
      <Card className={styles['header-wrapper']}>
        <UserSettingsDisplay
          currentUser={currentUser!}
          userDetails={userDetails!}
          loading={isLoading}
        />
      </Card>
      <Card>
        <Tabs activeTab={activeTab} onChange={setActiveTab} type="rounded">
          <Tabs.TabPane key="basic" title={t['userSetting.title.basicInfo']}>
            <UserSettingsForm
              currentUser={currentUser!}
              userDetails={userDetails!}
              loading={isLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            key="security"
            title={t['userSetting.title.security']}
            disabled
          >
            <></>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </ContentPage>
  );
}

export default UserInfo;
