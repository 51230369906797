// TODO - filter unused keys
const i18n = {
  'en-US': {
    'menu.visualization': 'Data Visualization',
    'menu.visualization.dashboards': 'Dashboards',
    'menu.visualization.trends': 'Trends',
    'menu.visualization.baseline': 'Baseline',
    'menu.analysis': 'Analysis',
    'menu.analysis.insights': 'Insights',
    'menu.analysis.models': 'Models',
    'menu.analysis.teaBuilder': 'TAE Builder',
    'menu.data': 'Data',
    'menu.data.inputs': 'Inputs',
    'menu.user': 'User Center',
    'menu.user.settings': 'User Settings',
    'menu.user.invitations': 'User Invitations',
    'menu.empty': 'No items',

    'menu.welcome': 'Welcome',
    'menu.dashboard': 'Dashboard',
    'menu.list': 'List',
    'menu.result': 'Result',
    'menu.exception': 'Exception',
    'menu.form': 'Form',
    'menu.profile': 'Profile',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.profile.basic': 'Basic Profile',
    'menu.list.cardList': 'Card List',
    'menu.visualization.dataAnalysis': 'Analysis',
    'menu.result.error': 'Error',
    'menu.form.group': 'Group Form',
    'menu.dashboard.monitor': 'Real-time Monitor',
    'menu.list.searchTable': 'Search Table',
    'menu.form.step': 'Step Form',
    'menu.result.success': 'Success',
    'menu.user.info': 'User Info',
    'menu.user.setting': 'User Setting',
    'menu.user.switchRoles': 'Switch Roles',
    'menu.user.role.admin': 'Admin',
    'menu.user.role.user': 'General User',
    'menu.dashboard.workplace': 'Workplace',
    'navbar.logout': 'Logout',
    'settings.title': 'Settings',
    'settings.themeColor': 'Theme Color',
    'settings.content': 'Content Setting',
    'settings.navbar': 'Navbar',
    'settings.menuWidth': 'Menu Width (px)',
    'settings.navbar.theme.toLight': 'Click to use light mode',
    'settings.navbar.theme.toDark': 'Click to use dark mode',
    'settings.menu': 'Menu',
    'settings.footer': 'Footer',
    'settings.otherSettings': 'Other Settings',
    'settings.colorWeak': 'Color Weak',
    'settings.alertContent':
      'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
    'settings.copySettings': 'Copy Settings',
    'settings.copySettings.message':
      'Copy succeeded, please paste to file src/settings.json.',
    'settings.close': 'Close',
    'settings.color.tooltip':
      '10 gradient colors generated according to the theme color',
    'message.tab.title.message': 'Message',
    'message.tab.title.notice': 'Notice',
    'message.tab.title.todo': 'ToDo',
    'message.allRead': 'All Read',
    'message.seeMore': 'SeeMore',
    'message.empty': 'Empty',
    'message.empty.tips': 'No Content',
    'message.lang.tips': 'Language switch to ',
    'navbar.search.placeholder': 'Please search'
  }
};

export default i18n;
