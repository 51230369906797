const i18n = {
  'en-US': {
    'setupPassword.form.title': 'Setup Password to CVector Energy',
    'setupPassword.form.password.errMsg': 'Password cannot be empty',
    'setupPassword.form.password.minLength':
      'Password must be at least 8 characters',
    'setupPassword.form.password.notSame': 'Passwords do not match',
    'setupPassword.form.password.placeholder': 'Password',
    'setupPassword.form.confirmPassword.placeholder': 'Confirm password',
    'setupPassword.form.reqErrMsg': 'Login error, please refresh and try again',
    'setupPassword.form.reqSubmit': 'Submit'
  }
};

export default i18n;
