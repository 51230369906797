import { generate, getRgbStr } from '@arco-design/color';
import { Popover, Typography } from '@arco-design/web-react';
import { SketchPicker } from 'react-color';
import useLocale from '../../hooks/useLocale';
import {
  layoutActions,
  layoutSelectors
} from '../../redux/slices/layout.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import locale from './locale/locale';
import styles from './style/color-panel.module.less';

function SettingsColor() {
  const settings = useAppSelector(layoutSelectors.selectSettings);
  const theme = useAppSelector(layoutSelectors.selectTheme);

  const t = useLocale(locale);

  const themeColor = settings.themeColor;
  const list = generate(themeColor, { list: true });
  const dispatch = useAppDispatch();

  return (
    <div>
      <Popover
        trigger="hover"
        position="bl"
        content={
          <SketchPicker
            color={themeColor}
            onChangeComplete={(color) => {
              const newColor = color.hex;
              dispatch(
                layoutActions.setSettings({ ...settings, themeColor: newColor })
              );
              const newList = generate(newColor, {
                list: true,
                dark: theme === 'dark'
              });
              newList.forEach((l, index) => {
                const rgbStr = getRgbStr(l);
                document.body.style.setProperty(
                  `--primary-${index + 1}`,
                  rgbStr
                );
              });
            }}
          />
        }
      >
        <div className={styles.input}>
          <div
            className={styles.color}
            style={{ backgroundColor: themeColor }}
          />
          <span>{themeColor}</span>
        </div>
      </Popover>
      <ul className={styles.ul}>
        {list.map((item, index) => (
          <li
            key={index}
            className={styles.li}
            style={{ backgroundColor: item }}
          />
        ))}
      </ul>
      <Typography.Paragraph style={{ fontSize: 12, textAlign: 'center' }}>
        {t['settings.color.tooltip']}
      </Typography.Paragraph>
    </div>
  );
}

export default SettingsColor;
