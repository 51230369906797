import { environmentVariables, ViteEnvironment } from './environment';

export const PROTOCOL_HTTP =
  environmentVariables.MODE === ViteEnvironment.DEVELOPMENT ? 'http' : 'https';
export const WS_CVEC_PROTOCOL_NAME = 'cvec_ws_protocol_1.0';

export const API_PREFIX_PATH = 'api';

export const API_OPERATIONAL_SUBPATH = 'operational';
export const API_READINGS_SUBPATH = 'sensor_reading';
export const API_AUTH_SUBPATH = 'auth';
export const API_HEX_SUBPATH = 'hex';

export const WEBSOCKET_SENSOR_READINGS_END_PATH = 'data';

export const HEX_IFRAME_BASE_HOSTNAME = 'https://app.hex.tech';
