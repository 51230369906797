import {
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Select,
  Tooltip
} from '@arco-design/web-react';
import {
  IconLanguage,
  IconMoonFill,
  IconPoweroff,
  IconSettings,
  IconSunFill,
  IconUser
} from '@arco-design/web-react/icon';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useLogoutUserMutation } from '../../communications/api/users.api';
import { ButtonShape, ButtonType } from '../../constants/arcoEnums';
import { Languages, languagesLabelMap } from '../../constants/i18n';
import { NavigationRoutes } from '../../constants/routes';
import { StorageKeys } from '../../constants/storageKeys';
import { Themes } from '../../constants/themes';
import useLocale from '../../hooks/useLocale';
import useStorage from '../../hooks/useStorage';
import {
  layoutActions,
  layoutSelectors
} from '../../redux/slices/layout.slice';
import { usersActions } from '../../redux/slices/users.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ThemeService } from '../../services/utils/theme.service';
import { GenericResponseResult } from '../../typings/web.model';
import Logo from '../Logo/Logo';
import Settings from '../Settings/Settings';
import NavbarIconButton from './NavbarIconButton';
import locale from './locale/locale';
import styles from './style/navbar.module.less';
import { LogoVariants } from '../../constants/logo';

function Navbar({ show }: { show: boolean }) {
  const t = useLocale(locale);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const lang = useAppSelector(layoutSelectors.selectLang);
  const theme = useAppSelector(layoutSelectors.selectTheme);

  const [_storageLang, setStorageLang] = useStorage(StorageKeys.LANG);
  const [_storageTheme, setStorageTheme] = useStorage(StorageKeys.THEME);

  const [logoutUser] = useLogoutUserMutation();
  const handleLogout = async () => {
    try {
      dispatch(layoutActions.setGlobalLoaderActive(true));
      const result = await logoutUser({}).unwrap();
      if (result.result === GenericResponseResult.Success) {
        dispatch(usersActions.setCurrentUser(null));
        navigate(NavigationRoutes.LOGIN);
      }
    } finally {
      dispatch(layoutActions.setGlobalLoaderActive(false));
    }
  };

  function onMenuItemClick(key: string) {
    if (key === 'logout') {
      handleLogout();
    } else if (key === 'user-settings') {
      navigate(NavigationRoutes.USER_SETTINGS);
    } else {
      // eslint-disable-next-line no-console
      console.log('Settings clicked', key);
    }
  }
  const langOptions = Object.entries(Languages).map(([_key, value]) => ({
    label: languagesLabelMap[value],
    value
  }));

  if (!show) {
    return (
      <div className={styles['fixed-settings']}>
        <Settings />
      </div>
    );
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="user-settings">
        <IconSettings className={styles['dropdown-icon']} />
        {t['menu.user.setting']}
      </Menu.Item>
      <Divider className={styles.divider} />
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        {t['navbar.logout']}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={clsx(styles.left)}>
        <div className={clsx(styles.logo)}>
          <Logo
            variant={
              theme === Themes.LIGHT
                ? LogoVariants.ROUND_BADGE_LOGO
                : LogoVariants.SQUARE_BADGE_LOGO
            }
            widthClass="w-10"
          />
          <div className={clsx(styles['logo-name'])}>{t['navbar.title']}</div>
        </div>
      </div>
      <ul className={styles.right}>
        <li>
          <Input.Search
            className={clsx(styles.round)}
            placeholder={t['navbar.search.placeholder']}
            disabled
          />
        </li>
        <li>
          <Select
            triggerElement={<NavbarIconButton icon={<IconLanguage />} />}
            options={langOptions}
            value={lang}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: 'br'
            }}
            trigger="hover"
            onChange={(value) => {
              dispatch(layoutActions.setLang(value));
              setStorageLang(value);
            }}
          />
        </li>
        <li>
          <Tooltip
            content={
              theme === Themes.LIGHT
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <NavbarIconButton
              icon={theme !== Themes.DARK ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => {
                const newTheme =
                  theme === Themes.LIGHT ? Themes.DARK : Themes.LIGHT;
                dispatch(layoutActions.setTheme(newTheme));
                ThemeService.changeTheme(newTheme);
                setStorageTheme(newTheme);
              }}
            />
          </Tooltip>
        </li>
        <li>
          <Settings />
        </li>
        <li>
          <Dropdown droplist={droplist} position="br">
            <Button
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              icon={<IconUser />}
            />
          </Dropdown>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
