import ContentPage from '../../../components/ContentPage/ContentPage';
import UnderConstruction from '../../../components/UnderConstruction/UnderConstruction';

type Props = {};

const Insights = (_props: Props) => {
  return (
    <ContentPage title="Insights">
      <UnderConstruction />
    </ContentPage>
  );
};

export default Insights;
