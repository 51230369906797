import { Carousel } from '@arco-design/web-react';
import cableCarImg from '../../assets/login/cable-car.jpg';
import greeceTowerImg from '../../assets/login/greece-tower.jpg';
import iceImg from '../../assets/login/ice.jpg';
import lightImg from '../../assets/login/light.jpg';
import loadingStationImg from '../../assets/login/loading-column.jpg';
import stationImg from '../../assets/login/station.jpg';
import windPowerImg from '../../assets/login/wind-power.jpg';
import styles from './style/carousel.module.less';

const imgSources: string[] = [
  cableCarImg,
  iceImg,
  greeceTowerImg,
  lightImg,
  loadingStationImg,
  stationImg,
  windPowerImg
];

export default function LandingCarousel() {
  return (
    <Carousel
      className={styles.carousel}
      animation="fade"
      autoPlay={true}
      indicatorType="dot"
      showArrow="hover"
    >
      {imgSources.map((src, index) => (
        <div key={index} className="w-full h-screen">
          <img src={src} className="object-cover w-full h-screen" />
        </div>
      ))}
    </Carousel>
  );
}
