import clsx from 'clsx';
import { useEffect } from 'react';
import {
  Flip,
  ToastContainer,
  ToastOptions,
  toast as showToast
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppErrorToast } from '../../errors/errors';
import {
  layoutActions,
  layoutSelectors
} from '../../redux/slices/layout.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Toast, ToastTypes } from '../../typings/toast.model';
import './style/toaster.css';

interface Props {
  className?: string;
}

const Toaster = ({ className }: Props) => {
  const toasts: Toast[] = useAppSelector(layoutSelectors.selectToasts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!toasts?.length) {
      return;
    }

    const opts: ToastOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      transition: Flip
    };

    toasts.forEach((toast) => {
      switch (toast.type) {
        case ToastTypes.ERROR:
          showToast.error(toast.message, opts);
          break;
        case ToastTypes.INFO:
          showToast.info(toast.message, opts);
          break;
        case ToastTypes.SUCCESS:
          showToast.success(toast.message, opts);
          break;
        case ToastTypes.WARNING:
          showToast.warning(toast.message, opts);
          break;
        default:
          throw new AppErrorToast('Toast type not recognized');
      }
    });
    dispatch(layoutActions.removeToasts(toasts.map((toast) => toast.id)));
  }, [toasts, dispatch]);

  return (
    <div className={clsx(className)}>
      <ToastContainer />
    </div>
  );
};

export default Toaster;
