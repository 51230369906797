import { SupabaseAuthClient } from '@supabase/supabase-js/dist/module/lib/SupabaseAuthClient';
import { appDataSchemaClient, publicSchemaClient } from '../../db/db';
import {
  SupabaseLoginResult,
  SupabaseLogoutResult,
  SupabaseUser
} from '../../typings/users.model';
import { GenericResponseResult, Try } from '../../typings/web.model';

export class AuthService {
  authClient: SupabaseAuthClient;

  constructor() {
    this.authClient = publicSchemaClient.auth;
  }

  async login(user: string, password: string): Promise<SupabaseLoginResult> {
    const { data, error } = await this.authClient.signInWithPassword({
      email: user,
      password: password
    });
    return {
      user: data.user,
      session: data.session,
      weakPassword: data?.weakPassword ?? null,
      error: error
    };
  }

  async refreshSessionWithToken(
    token: string
  ): Promise<Try<SupabaseUser, Error>> {
    const { data, error } = await this.authClient.refreshSession({
      refresh_token: token
    });
    return { data: data.user, error };
  }

  async logout(): Promise<SupabaseLogoutResult> {
    const { error } = await this.authClient.signOut();
    return {
      result: error
        ? GenericResponseResult.Failure
        : GenericResponseResult.Success,
      error: error
    };
  }

  async getSession() {
    return await this.authClient.getSession();
  }

  async getJwtAuthorizedRoleInfo(): Promise<SupabaseUser | null> {
    const response = await appDataSchemaClient.rpc('who_am_i');
    if (response.data) {
      return JSON.parse(response.data) as SupabaseUser;
    }
    return null;
  }

  async getUserInfo(jwt?: string): Promise<Try<SupabaseUser, Error>> {
    const { data, error } = jwt
      ? await this.authClient.getUser(jwt)
      : await this.authClient.getUser();
    return { data: data.user, error };
  }

  async changeCurrentUSerPassword(
    password: string
  ): Promise<Try<SupabaseUser, Error>> {
    const { data, error } = await this.authClient.updateUser({
      password: password
    });
    return { data: data.user, error };
  }
}
