import ContentPage from '../../../components/ContentPage/ContentPage';
import UnderConstruction from '../../../components/UnderConstruction/UnderConstruction';

type Props = {};

const Inputs = (_props: Props) => {
  return (
    <ContentPage title="Inputs">
      <UnderConstruction />
    </ContentPage>
  );
};

export default Inputs;
