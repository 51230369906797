const i18n = {
  'en-US': {
    'models.hexProjects.adminSubtitle': 'Modify Hex projects to display',
    'models.hexProjects.displaySubtitle': 'Available Hex projects',
    'models.hexProjects.admin': 'Admin',
    'models.hexProjects.table.projectName': 'Name',
    'models.hexProjects.table.creator': 'Creator',
    'models.hexProjects.table.createdAt': 'Created at',
    'models.hexProjects.table.updatedAt': 'Updated at',
    'models.hexProjects.table.action': 'Action',
    'models.hexProjects.table.load': 'Load',
    'models.hexProjects.table.noDescription': 'No description',
    'models.hexProjects.table.noData': 'No data',
    'models.hexProjects.table.expand': 'Expand',
    'models.hexProjects.table.backToList': 'Back to projects',
    'models.hexProjects.table.loginRefresh':
      'After login via confirmation email, refresh this page',
    'models.hexProjects.table.refresh': 'Refresh page'
  }
};

export default i18n;
