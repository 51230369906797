import { Card, Select, Tag } from '@arco-design/web-react';
import {
  IconCheckCircleFill,
  IconMinusCircle
} from '@arco-design/web-react/icon';
import Can from '../../../components/auth/Can/Can';
import { TailwindColors } from '../../../constants/tailwindColors';
import useLocale from '../../../hooks/useLocale';
import usePermissions from '../../../hooks/usePermissions';
import { hexActions } from '../../../redux/slices/hex.slice';
import { useAppDispatch } from '../../../redux/store';
import { HexProject } from '../../../typings/hex.model';
import locale from './locale/locale';
import styles from './style/models.module.less';

type Props = {
  projects: HexProject[] | undefined;
  visibleIds: string[];
  loading: boolean;
};

const ModelsAdminProjectsChoice = ({
  projects,
  loading,
  visibleIds
}: Props) => {
  const dispatch = useAppDispatch();
  const { uniquePermissions } = usePermissions();

  const t = useLocale(locale);

  const composeProjectSelector = () => (
    <Select
      mode="multiple"
      placeholder={t['models.hexProjects.selectProjects']}
      style={{ width: 345 }}
      defaultValue={visibleIds}
      allowClear
      loading={loading}
      disabled={loading}
      onChange={(values: string[]) => {
        dispatch(hexActions.setVisibleProjectIds(values));
      }}
      renderTag={(_tagOptions, index, valueList) => {
        return index === 0 ? (
          <span
            style={{ marginLeft: 8 }}
          >{`${valueList.length} ${t['models.hexProjects.projectsSelected']}`}</span>
        ) : null;
      }}
    >
      {(projects ?? []).map((option) => (
        <Select.Option key={option.id} value={option.id}>
          {option.title}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <Can
      requiredPermissions={new Set(['analysis_models.write'])}
      userPermissions={uniquePermissions}
    >
      {(allowed) =>
        allowed ? (
          <Card className="mb-6">
            <div className={styles.subtitle}>
              {t['models.hexProjects.adminSubtitle']}
            </div>
            <div className={styles.selectWrapper}>
              {composeProjectSelector()}
            </div>
            {visibleIds.length ? (
              <div className="pb-3">
                {visibleIds.map((pid) => {
                  const foundProject = (projects ?? []).find(
                    (p) => p.id === pid
                  );
                  if (!foundProject) {
                    return null;
                  }
                  return (
                    <Tag
                      className={'mr-3'}
                      key={pid}
                      icon={<IconCheckCircleFill />}
                    >
                      {foundProject.title}
                    </Tag>
                  );
                })}
              </div>
            ) : (
              <IconMinusCircle />
            )}
            <Tag className={styles.tag} color={TailwindColors.amber[500]}>
              {t['models.hexProjects.admin']}
            </Tag>
          </Card>
        ) : (
          <div></div>
        )
      }
    </Can>
  );
};

export default ModelsAdminProjectsChoice;
