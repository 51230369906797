import { Button } from '@arco-design/web-react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './style/icon-button.module.less';

function NavbarIconButton(props: any, ref: any) {
  const { icon, className, ...rest } = props;

  return (
    <Button
      ref={ref}
      icon={icon}
      shape="circle"
      type="secondary"
      className={clsx(styles['icon-button'], className)}
      {...rest}
    />
  );
}

export default forwardRef(NavbarIconButton);
