import { createApi } from '@reduxjs/toolkit/query/react';
import { AppError } from '../../errors/errors';
import { StorageService } from '../../services/data/storage.data.service';
import { BucketObject } from '../../typings/files.model';
import { axiosBaseQuery } from './baseClients';

const storageService = new StorageService();

export const storageApi = createApi({
  reducerPath: 'storageApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/`
  }),
  tagTypes: ['File'],
  endpoints: (builder) => ({
    getObjectDetails: builder.query<BucketObject, { fileObjectId: string }>({
      queryFn: async ({ fileObjectId }) => {
        const res = await storageService.selectFileById(fileObjectId);
        if (res.error || !res.data) {
          throw new AppError(res.error?.message ?? 'No file found');
        }
        const publicPath: string = await storageService.selectPublicUrlByPath(
          res.data.name
        );
        return { data: { ...res.data, publicPath } };
      },
      providesTags: ['File']
    })
  })
});

export const { useGetObjectDetailsQuery } = storageApi;
