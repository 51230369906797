const i18n = {
  'en-US': {
    'login.form.title': 'Login to CVector Energy',
    'login.form.sub-title': 'Dynamic Dashboards',
    'login.form.username.errMsg': 'Username cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.username.errEmailMsg': 'Incorrect email format',
    'login.form.login.errMsg': 'Login error, please refresh and try again',
    'login.form.username.placeholder': 'Username',
    'login.form.password.placeholder': 'Password',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password',
    'login.form.login': 'Login',
    'login.form.register': 'Register account'
  }
};

export default i18n;
