import memoize from 'lodash/memoize';
import { PermissionTypes } from '../../constants/permissions';

export class PermissionService {
  static hasPermission = memoize(
    (
      allowedPermissions: Set<PermissionTypes>,
      userPermissions: Set<PermissionTypes>
    ): boolean => {
      return Array.from(allowedPermissions).some((permission) =>
        userPermissions.has(permission)
      );
    }
  );
}
