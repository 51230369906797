import clsx from 'clsx';
import { useEffect, useEffect as useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';
import { NavigationRoutes } from '../../constants/routes';
import { StorageKeys } from '../../constants/storageKeys';
import { Themes } from '../../constants/themes';
import useStorage from '../../hooks/useStorage';
import { usersSelectors } from '../../redux/slices/users.slice';
import { useAppSelector } from '../../redux/store';
import { ThemeService } from '../../services/utils/theme.service';
import LoginForm from './LoginForm';
import styles from './style/login.module.less';
import LandingCarousel from '../../components/LandingCarousel/LandingCarousel';

const Login = () => {
  const currentUser = useAppSelector(usersSelectors.selectCurrentUser);
  const navigate = useNavigate();
  const [storageTheme] = useStorage(StorageKeys.THEME);

  useLayoutEffect(() => {
    document.body.setAttribute('arco-theme', Themes.LIGHT);

    return () => {
      if (storageTheme === Themes.DARK) {
        ThemeService.changeTheme(Themes.DARK);
      }
    };
  }, [storageTheme]);

  useEffect(() => {
    if (currentUser) {
      setTimeout(() => {
        navigate(NavigationRoutes.VISUALIZATION_DASHBOARDS);
      }, 100);
    }
  }, [currentUser, navigate]);

  return (
    <div className={clsx(styles.container, 'grid grid-cols-12')}>
      <div
        className={clsx(
          styles.banner,
          'z-10 hidden md:flex col-span-12 md:col-span-6 lg:col-span-4'
        )}
      >
        <div className={styles['banner-inner']}>
          <LandingCarousel />
        </div>
      </div>
      <div
        className={clsx(
          styles.content,
          'col-span-12 md:col-span-6 lg:col-span-8'
        )}
      >
        <div className={styles['content-inner']}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
