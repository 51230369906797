import clsx from 'clsx';
import './style/loader.css';

type Props = {
  className?: string;
  useMask?: boolean;
};

const Loader = ({ className, useMask = false }: Props) => {
  return (
    <div
      className={clsx(
        className,
        'z-30 w-full h-full flex justify-center items-center',
        { 'bg-neutral-950/10 transition-colors': useMask }
      )}
    >
      <div className="loader" />
    </div>
  );
};

export default Loader;
