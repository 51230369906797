import { useCallback, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {
  environmentVariables,
  ViteEnvironment
} from '../../constants/environment';
import { WS_CVEC_PROTOCOL_NAME } from '../../constants/web';
import { layoutActions } from '../../redux/slices/layout.slice';
import { useAppDispatch } from '../../redux/store';
import { ConversionService } from '../../services/utils/conversion.service';

type Props<K, T> = {
  connectionProps: K;
  serverHostname: string;
  websocketUrlPath: string;
  accessToken: string;
  handleIncomingMessageCallback: (message: T) => void;
};

const WebsocketProvider = <K, T>({
  connectionProps,
  websocketUrlPath,
  serverHostname,
  accessToken,
  handleIncomingMessageCallback
}: Props<K, T>) => {
  const SOCKET_URL = `${environmentVariables.MODE === ViteEnvironment.PRODUCTION ? 'wss' : 'ws'}://${serverHostname}/${websocketUrlPath}?auth=${accessToken}`;

  const [initialized, setInitialized] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket<string>(
    SOCKET_URL,
    {
      share: false,
      shouldReconnect: () => true,
      protocols: [WS_CVEC_PROTOCOL_NAME]
    }
  );

  useEffect(() => {
    if (!initialized) {
      if (readyState === ReadyState.OPEN) {
        // TODO - remove console after some testing with proper WS endpoints
        // eslint-disable-next-line no-console
        // console.log('WebsocketProvider - handle outgoing message', connectionProps);
        sendJsonMessage(
          new ConversionService<any>().toSnakeCase(connectionProps)
        );
        setInitialized(true);
      } else {
        // TODO - remove console after some testing with proper WS endpoints
        // eslint-disable-next-line no-console
        // console.log('WebsocketProvider - handle outgoing message', 'not ready');
      }
    }
  }, [readyState, sendJsonMessage, initialized, connectionProps]);

  const handleIncomingMessage = useCallback(
    (lastMessage: string) => {
      if (lastMessage) {
        try {
          // TODO - remove console after some testing with proper WS endpoints
          // eslint-disable-next-line no-console
          // console.log('WebsocketProvider - handle incoming message', lastMessage);
          const parsedMessageData: T = new ConversionService<T>().toCamelCase(
            JSON.parse(lastMessage as string)
          );
          handleIncomingMessageCallback(parsedMessageData);
        } catch (error) {
          console.error('Error parsing incoming message -', error);
          dispatch(
            layoutActions.addErrorToast('Error parsing incoming message')
          );
        }
      }
    },
    [handleIncomingMessageCallback, dispatch]
  );

  useEffect(() => {
    handleIncomingMessage(lastJsonMessage);
  }, [lastJsonMessage, handleIncomingMessage]);

  return <></>;
};

export default WebsocketProvider;
