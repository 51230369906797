import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { hexApi } from '../communications/api/hex.api';
import { operationalApi } from '../communications/api/operational.api';
import { sensorReadingsApi } from '../communications/api/sensorReadings.api';
import { storageApi } from '../communications/api/storage.api';
import { usersApi } from '../communications/api/users.api';
import {
  ViteEnvironment,
  environmentVariables
} from '../constants/environment';
import { hexReducer } from './slices/hex.slice';
import { layoutReducer } from './slices/layout.slice';
import { sensorReadingsReducer } from './slices/sensorReadings.slice';
import { usersReducer } from './slices/users.slice';

export const store = configureStore({
  reducer: {
    sensorReadings: sensorReadingsReducer,
    layout: layoutReducer,
    users: usersReducer,
    hex: hexReducer,
    [sensorReadingsApi.reducerPath]: sensorReadingsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [operationalApi.reducerPath]: operationalApi.reducer,
    [storageApi.reducerPath]: storageApi.reducer,
    [hexApi.reducerPath]: hexApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(sensorReadingsApi.middleware)
      .concat(usersApi.middleware)
      .concat(operationalApi.middleware)
      .concat(storageApi.middleware)
      .concat(hexApi.middleware),
  devTools: environmentVariables.MODE !== ViteEnvironment.PRODUCTION
});

// INFO - refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

// Infer types
export type AppRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Redux hooks with inferred types (replace useDispatch and useSelector)
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<AppRootState>();
