import { Button, Card, Select, Tag, Typography } from '@arco-design/web-react';
import {
  IconCheckCircleFill,
  IconMinusCircle
} from '@arco-design/web-react/icon';
import { useEffect, useState } from 'react';
import { useGetAvailableTagsQuery } from '../../../communications/api/sensorReadings.api';
import WebsocketProvider from '../../../communications/providers/WebsocketProvider';
import ContentPage from '../../../components/ContentPage/ContentPage';
import ResultCard from '../../../components/ResultCard/ResultCard';
import { ButtonType } from '../../../constants/arcoEnums';
import { environmentVariables } from '../../../constants/environment';
import {
  API_PREFIX_PATH,
  API_READINGS_SUBPATH,
  WEBSOCKET_SENSOR_READINGS_END_PATH
} from '../../../constants/web';
import { layoutSelectors } from '../../../redux/slices/layout.slice';
import { sensorReadingsActions } from '../../../redux/slices/sensorReadings.slice';
import { usersSelectors } from '../../../redux/slices/users.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  SensorReadingsChange,
  ShortMockRequestParams,
  ShortMockSensorReading
} from '../../../typings/sensorReadings.model';
import TrendsMockChart from './TrendsReadingsChart';

type Props = {};

const Trends = (_props: Props) => {
  const [chosenSensors, setChosenSensors] = useState<string[]>([]);
  const [activeSensors, setActiveSensors] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const user = useAppSelector(usersSelectors.selectCurrentUser);
  const backendActive = useAppSelector(layoutSelectors.selectBackendActive);
  const {
    data: tagsData,
    error: _tagsError,
    isLoading: tagsLoading
  } = useGetAvailableTagsQuery(null, {
    skip: !backendActive
  });

  const handleIncomingMessageCallback = (message: {
    sensorReadings: ShortMockSensorReading[];
  }) => {
    const change: SensorReadingsChange = {
      currentTagNames: activeSensors,
      readings: message.sensorReadings
    };
    dispatch(sensorReadingsActions.addShortMockStreamSensorReadings(change));
  };

  useEffect(() => {
    dispatch(
      sensorReadingsActions.removeAllShortMockStreamSensorReadings(null)
    );
  }, [dispatch]);

  const composeOption = (option: string) => (
    <Select.Option key={option} value={option}>
      {option}
    </Select.Option>
  );

  const composeTag = (tag: string) => {
    return (
      <Tag className={'mr-3'} key={tag} icon={<IconCheckCircleFill />}>
        {tag}
      </Tag>
    );
  };

  const handleSensorsChange = () => {
    dispatch(
      sensorReadingsActions.removeAllShortMockStreamSensorReadings(null)
    );
    setActiveSensors(chosenSensors);
  };

  return (
    <ContentPage title="Trends">
      {backendActive && user?.session.access_token ? (
        <>
          <Card className={'mb-4'}>
            <Typography.Title heading={5} className="pb-5">
              Sensor Choice
            </Typography.Title>
            <Select
              mode="multiple"
              placeholder="Select tags"
              style={{ width: 345 }}
              defaultValue={[]}
              allowClear
              loading={tagsLoading}
              disabled={tagsLoading}
              onChange={(values: string[]) => setChosenSensors(values)}
            >
              {tagsData?.availableTags.map((option) =>
                composeOption(option.tagName)
              )}
            </Select>
            <div className="pt-6">Chosen items</div>
            {chosenSensors.length ? (
              <div className="pb-3">
                {chosenSensors.map((tag) => composeTag(tag))}
              </div>
            ) : (
              <IconMinusCircle />
            )}
            <div className="pb-3 pt-4">Confirm sensor choice</div>
            <Button
              type={ButtonType.PRIMARY}
              onClick={handleSensorsChange}
              disabled={
                chosenSensors.sort().join('-') ===
                activeSensors.sort().join('-')
              }
            >
              Confirm
            </Button>
          </Card>

          <Card>
            <Typography.Title heading={5} className="pb-5">
              Mock Sensor Readings
            </Typography.Title>

            {activeSensors.length ? (
              <>
                <WebsocketProvider<
                  ShortMockRequestParams,
                  { sensorReadings: ShortMockSensorReading[] }
                >
                  accessToken={user.session.access_token}
                  connectionProps={{
                    sensorIds: activeSensors,
                    intervalMs: 1000
                  }}
                  websocketUrlPath={`${API_PREFIX_PATH}/${API_READINGS_SUBPATH}/${WEBSOCKET_SENSOR_READINGS_END_PATH}`}
                  serverHostname={environmentVariables.VITE_API_HOSTNAME}
                  handleIncomingMessageCallback={handleIncomingMessageCallback}
                />

                <TrendsMockChart tagNames={activeSensors} />
              </>
            ) : (
              <Typography.Text>No sensors chosen</Typography.Text>
            )}
          </Card>
        </>
      ) : (
        <ResultCard resultStatus={'error'} subtitle="Backend not active" />
      )}
    </ContentPage>
  );
};

export default Trends;
