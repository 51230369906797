import { PostgrestError, SupabaseClient } from '@supabase/supabase-js';
import { appDataSchemaClient } from '../../db/db';
import { Database as DatabaseAppDataSchema } from '../../db/schemas/app_data.schema';
import {
  CreateUserDetails,
  UserDetails
} from '../../typings/userDetails.model';
import { Try } from './../../typings/web.model';
import { ConversionService } from './../utils/conversion.service';

export class UserDetailsService {
  client: SupabaseClient<DatabaseAppDataSchema>;

  constructor() {
    this.client = appDataSchemaClient;
  }

  selectAllUserDetails: () => Promise<Try<UserDetails[], PostgrestError>> =
    async () => {
      const { data, error } = await this.client
        .from('account_details')
        .select('*');
      const service = new ConversionService<UserDetails>();
      const convertedData = data
        ? data.map((record) => service.toCamelCase(record))
        : null;
      return { data: convertedData, error };
    };

  selectUserDetailsByAuthId: (
    auth_id: string
  ) => Promise<Try<UserDetails, PostgrestError>> = async (auth_id: string) => {
    const { data, error } = await this.client
      .from('account_details')
      .select('*')
      .eq('auth_id', auth_id)
      .single();
    const service = new ConversionService<UserDetails>();
    const convertedData = data ? service.toCamelCase(data) : null;
    return { data: convertedData, error };
  };

  selectUserDetailsById: (
    id: string
  ) => Promise<Try<UserDetails, PostgrestError>> = async (id: string) => {
    const { data, error } = await this.client
      .from('account_details')
      .select('*')
      .eq('id', id)
      .single();
    const service = new ConversionService<UserDetails>();
    const convertedData = data ? service.toCamelCase(data) : null;
    return { data: convertedData, error };
  };

  createUserDetails: (
    payload: CreateUserDetails
  ) => Promise<Try<UserDetails, PostgrestError>> = async (payload) => {
    const service = new ConversionService<
      DatabaseAppDataSchema['app_data']['Tables']['account_details']['Insert']
    >();
    const convertedData = service.toSnakeCase(payload);
    const { data, error } = await this.client
      .from('account_details')
      .insert(convertedData);
    if (error) {
      return { data, error };
    }
    const res = await this.selectUserDetailsByAuthId(payload.authId);
    return { data: res.data, error: res.error };
  };

  updateUserDetailsById: (
    id: string,
    userDetails: Partial<UserDetails>
  ) => Promise<Try<UserDetails, PostgrestError>> = async (id, userDetails) => {
    const service = new ConversionService<
      DatabaseAppDataSchema['app_data']['Tables']['account_details']['Update']
    >();
    const convertedData = service.toSnakeCase(userDetails);
    const response = await this.client
      .from('account_details')
      .update(convertedData)
      .eq('id', id);
    if (response.error) {
      return { data: response.data, error: response.error };
    }
    const res = await this.selectUserDetailsById(id);
    return { data: res.data, error: res.error };
  };
}
