import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export class ConversionService<T> {
  toCamelCase: (data: any) => T = (data: any) => {
    return data ? camelcaseKeys(data, { deep: true }) : data;
  };
  toSnakeCase: (data: any) => T = (data: any) => {
    return data ? snakecaseKeys(data, { deep: true }) : data;
  };
}
