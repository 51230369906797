// TODO - do we need to rename this file in the future ?

export enum ButtonType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DASHED = 'dashed',
  TEXT = 'text',
  OUTLINE = 'outline'
}

export enum ButtonStatus {
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
  DEFAULT = 'default'
}

export enum ButtonSize {
  MINI = 'mini',
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large'
}

export enum ButtonShape {
  CIRCLE = 'circle',
  ROUND = 'round',
  SQUARE = 'square'
}

export enum BadgeColors {
  RED = 'red',
  ORANGERED = 'orangered',
  ORANGE = 'orange',
  GOLD = 'gold',
  LIME = 'lime',
  GREEN = 'green',
  CYAN = 'cyan',
  ARCOBLUE = 'arcoblue',
  PURPLE = 'purple',
  PINKPURPLE = 'pinkpurple',
  MAGENTA = 'magenta',
  GRAY = 'gray',
  BLUE = 'blue'
}
