import { Card, Tabs } from '@arco-design/web-react';
import { IconIdcard, IconUserAdd } from '@arco-design/web-react/icon';
import { useState } from 'react';
import ContentPage from '../../../components/ContentPage/ContentPage';
import useLocale from '../../../hooks/useLocale';
import InvitationsForm from './InvitationsForm';
import InvitationsUsers from './InvitationsUsers';
import locale from './locale/locale';
import styles from './styles/invitations.module.less';

type Props = {};

const Invitations = (_props: Props) => {
  const t = useLocale(locale);

  const [activeTab, setActiveTab] = useState<string>('1');

  return (
    <ContentPage title="Invitations">
      <Card className={styles['card-wrapper']}>
        <Tabs activeTab={activeTab} onChange={setActiveTab}>
          <Tabs.TabPane
            key="1"
            title={
              <span>
                <IconIdcard style={{ marginRight: 6 }} />
                {t['invitations.tabs.users']}
              </span>
            }
          >
            <InvitationsUsers />
          </Tabs.TabPane>
          <Tabs.TabPane
            key="2"
            title={
              <span>
                <IconUserAdd style={{ marginRight: 6 }} />
                {t['invitations.tabs.invite']}
              </span>
            }
          >
            <InvitationsForm onChangeTab={(key) => setActiveTab(key)} />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </ContentPage>
  );
};

export default Invitations;
