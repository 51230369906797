const i18n = {
  'en-US': {
    'menu.user': 'Personal Center',
    'menu.user.setting': 'User Setting',
    'userSetting.menu.title.info': 'Personal Information',
    'userSetting.menu.title.account': 'Account Setting',
    'userSetting.menu.title.password': 'Password',
    'userSetting.menu.title.message': 'Message Notification',
    'userSetting.menu.title.result': 'Result',
    'userSetting.menu.title.data': 'Export Data',
    'userSetting.saveSuccess': 'Save Success',
    'userSetting.title.basicInfo': 'Basic Information',
    'userSetting.title.security': 'Security Settings',
    'userSetting.label.avatar': 'Avatar',
    'userSetting.label.name': 'User Name',
    'userSetting.label.accountId': 'Account ID',
    'userSetting.label.avatarId': 'Avatar ID',
    'userSetting.label.avatarPath': 'Avatar URL',
    'userSetting.value.noAvatar': 'No Avatar',
    'userSetting.label.verified': 'Whether Verified',
    'userSetting.value.verified': 'Verified',
    'userSetting.value.notVerified': 'Not Verified',
    'userSetting.label.emailVerified': 'Email Verified',
    'userSetting.label.phoneNumber': 'Phone Number',
    'userSetting.value.noPhone': 'No phone',
    'userSetting.label.birthDate': 'Birth Date',
    'userSetting.label.firstName': 'First Name',
    'userSetting.label.lastName': 'Last Name',
    'userSetting.value.noData': 'No Data',
    'userSetting.label.registrationTime': 'Registration time',
    'userSetting.btn.edit': 'Edit',
    'userSetting.save': 'Save',
    'userSetting.reset': 'Reset',

    'userSetting.info.email': 'Email',
    'userSetting.info.email.placeholder':
      'Please enter your email address, such as xxx@bytedance.com',
    'userSetting.info.nickName': 'Nickname',
    'userSetting.info.nickName.placeholder': 'Please enter your nickname',
    'userSetting.info.area': 'Country / Region',
    'userSetting.info.area.placeholder': 'Please select a country/region',
    'userSetting.info.location': 'Your location',
    'userSetting.info.address': 'Specific address',
    'userSetting.info.address.placeholder': 'Please enter your address',
    'userSetting.info.profile': 'Personal profile',
    'userSetting.info.profile.placeholder':
      'Please enter your profile, no more than 200 words.',
    'userSetting.security.password': 'Login Password',
    'userSetting.security.password.tips':
      'Has been set. The password has at least 6 characters, supports numbers, letters and special characters except spaces, and must contain both numbers and uppercase and lowercase letters. ',
    'userSetting.security.question': 'Secure question',
    'userSetting.security.question.placeholder':
      'You have not set a secret security question, which can effectively protect the security of your account.',
    'userSetting.security.phone': 'Secure phone',
    'userSetting.security.phone.tips': 'Bound:',
    'userSetting.security.email': 'Secure email',
    'userSetting.security.email.placeholder':
      'You have not set up an email address yet. The bound email address can be used to retrieve your password, receive notifications, etc.',
    'userSetting.verified.enterprise': 'Enterprise real-name certification',
    'userSetting.verified.records': 'Certification records',
    'userSetting.verified.label.accountType': 'Account Type',

    'userSetting.verified.label.isVerified': 'Authentication status',
    'userSetting.verified.label.verifiedTime': 'Authentication time',
    'userSetting.verified.label.legalPersonName': 'Legal Person name',
    'userSetting.verified.label.certificateType':
      'Type of legal person certificate',
    'userSetting.verified.label.certificationNumber':
      'Legal person certification number',
    'userSetting.verified.label.enterpriseName': 'Enterprise Name',

    'userSetting.verified.label.enterpriseCertificateType':
      'Enterprise certificate type',
    'userSetting.verified.label.organizationCode': 'Organization Code',

    'userSetting.verified.authType': 'Authentication type',
    'userSetting.verified.authContent': 'Authentication content',
    'userSetting.verified.authStatus': 'Current status',
    'userSetting.verified.createdTime': 'Created time',
    'userSetting.verified.operation': 'Operation',
    'userSetting.verified.operation.view': 'View',
    'userSetting.verified.operation.revoke': 'Revoke',
    'userSetting.verified.status.success': 'passed',
    'userSetting.verified.status.waiting': 'under review'
  }
};

export default i18n;
