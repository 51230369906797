import { Button, Form, Input } from '@arco-design/web-react';
import { IconRobotAdd } from '@arco-design/web-react/icon';
import { useEffect } from 'react';
import { useInviteUserAdminMutation } from '../../../communications/api/users.api';
import useLocale from '../../../hooks/useLocale';
import { layoutActions } from '../../../redux/slices/layout.slice';
import { useAppDispatch } from '../../../redux/store';
import locale from './locale/locale';
import styles from './styles/invitations.module.less';

type Props = {
  onChangeTab: (key: string) => void;
};

const InvitationsForm = ({ onChangeTab }: Props) => {
  const t = useLocale(locale);

  const dispatch = useAppDispatch();

  const [inviteUser, { error: inviteError, isLoading: inviteLoading }] =
    useInviteUserAdminMutation();

  const handleInvite = async (email: string) => {
    await inviteUser({
      email
    }).unwrap();
    dispatch(layoutActions.addSuccessToast(`User ${email} invitation sent`));
    onChangeTab('1');
  };

  useEffect(() => {
    if (inviteError) {
      dispatch(layoutActions.addErrorToast('Invite user error'));
    }
  }, [inviteError, dispatch]);

  const [form] = Form.useForm();

  const onSubmitClick = () => {
    if (form) {
      form.validate().then((values) => {
        handleInvite(values.email);
      });
    }
  };

  return (
    <div>
      <div className={styles.subtitle}>{t['invitations.subtitle']}</div>
      <Form
        form={form}
        className={styles.invitationForm}
        layout="vertical"
        initialValues={{ email: '' }}
      >
        <Form.Item
          field="email"
          rules={[
            { required: true, message: t['invitations.form.email.errMsg'] },
            { type: 'email', message: t['invitations.form.email.errEmailMsg'] }
          ]}
        >
          <Input
            prefix={<IconRobotAdd />}
            placeholder={t['invitations.form.email.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Button
          type="primary"
          long
          onClick={onSubmitClick}
          loading={inviteLoading}
        >
          {t['invitations.form.invite']}
        </Button>
      </Form>
    </div>
  );
};

export default InvitationsForm;
