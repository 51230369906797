import { AuthError } from '@supabase/supabase-js';

export class AppError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AppError';
  }
}

export class AppErrorAuth extends AuthError {
  constructor(message: string) {
    super(message);
    this.name = 'AuthError';
  }
}

export class AppErrorToast extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ToastError';
  }
}

export class NotImplementedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotImplementedError';
  }
}

export class LocaleError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'LocaleError';
  }
}
