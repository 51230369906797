import { createClient } from '@supabase/supabase-js';
import { environmentVariables } from '../constants/environment';
import { Database as DatabaseAppDataSchema } from './schemas/app_data.schema';
import { Database as StorageDatabase } from './schemas/storage.schema';

export const publicSchemaClient = createClient<'public'>(
  environmentVariables.VITE_SUPABASE_HOSTNAME,
  environmentVariables.VITE_SUPABASE_KEY
);

export const appDataSchemaClient = createClient<DatabaseAppDataSchema>(
  environmentVariables.VITE_SUPABASE_HOSTNAME,
  environmentVariables.VITE_SUPABASE_KEY,
  { db: { schema: 'app_data' } }
);

export const storageSchemaClient = createClient<StorageDatabase>(
  environmentVariables.VITE_SUPABASE_HOSTNAME,
  environmentVariables.VITE_SUPABASE_KEY,
  { db: { schema: 'storage' } }
);
