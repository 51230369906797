import { Button, Result, ResultProps } from '@arco-design/web-react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { NavigationRoutes } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import localeFile from './locale/locale';
import styles from './style/result_card.module.less';

export type ResultCardStatuses = ResultProps['status'];

type Props = {
  resultStatus: ResultCardStatuses;
  subtitle: string;
};

const ResultCard = ({
  resultStatus,
  subtitle,
  children
}: PropsWithChildren<Props>) => {
  const t = useLocale(localeFile);
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <Result
        children={children}
        className={styles.result}
        status={resultStatus}
        subTitle={subtitle}
        extra={
          <Button
            key="back"
            type="primary"
            onClick={() => navigate(NavigationRoutes.HOME)}
          >
            {t['button.back']}
          </Button>
        }
      />
    </div>
  );
};

export default ResultCard;
