import { Themes } from '../../constants/themes';

export class ThemeService {
  static changeTheme(theme: Themes) {
    if (theme === Themes.DARK) {
      document.body.setAttribute('arco-theme', Themes.DARK);
    } else {
      document.body.removeAttribute('arco-theme');
    }
  }
}
