import {
  Button,
  Checkbox,
  Form,
  Input,
  Link,
  Space
} from '@arco-design/web-react';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import { AuthError } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLazyLoginUserQuery } from '../../communications/api/users.api';
import Logo from '../../components/Logo/Logo';
import { LogoVariants } from '../../constants/logo';
import { PermissionTypes } from '../../constants/permissions';
import { NavigationRoutes } from '../../constants/routes';
import { StorageKeys } from '../../constants/storageKeys';
import useLocale from '../../hooks/useLocale';
import useStorage from '../../hooks/useStorage';
import { layoutActions } from '../../redux/slices/layout.slice';
import { usersActions } from '../../redux/slices/users.slice';
import { useAppDispatch } from '../../redux/store';
import locale from './locale/locale';
import styles from './style/login.module.less';
import { SupabaseUser } from '../../typings/users.model';
import { jwtDecode } from 'jwt-decode';

export default function LoginForm() {
  const [loginParams, setLoginParams, removeLoginParams] = useStorage(
    StorageKeys.LOGIN_PARAMS
  );
  const t = useLocale(locale);
  const [rememberPassword, setRememberPassword] = useState(!!loginParams);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginUser, { error: loginError, isLoading: loginLoading }] =
    useLazyLoginUserQuery();

  const handleLogin = async (username: string, password: string) => {
    const result = await loginUser({
      user: username,
      password
    }).unwrap();
    if (result.user && result.session) {
      if (rememberPassword) {
        setLoginParams(JSON.stringify({ username, password }));
      } else {
        removeLoginParams();
      }
      const decodedUser: SupabaseUser = jwtDecode<SupabaseUser>(
        result.session.access_token
      );
      dispatch(
        usersActions.setCurrentUser({
          user: result.user,
          session: result.session,
          permissions: (decodedUser.user_permissions?.split(',') ??
            []) as PermissionTypes[],
          role: result.user.user_role
        })
      );
      navigate(NavigationRoutes.VISUALIZATION_DASHBOARDS);
    }
  };

  useEffect(() => {
    if (loginError) {
      dispatch(
        layoutActions.addErrorToast(
          (loginError as AuthError)?.message ?? t['login.form.login.errMsg']
        )
      );
    }
  }, [loginError, dispatch, t]);

  const [form] = Form.useForm();

  useEffect(() => {
    const rememberPassword = !!loginParams;
    setRememberPassword(rememberPassword);
    if (form && rememberPassword) {
      const parseParams = JSON.parse(loginParams);
      form.setFieldsValue(parseParams);
    }
  }, [loginParams, form]);

  const onSubmitClick = () => {
    if (form) {
      form.validate().then((values) => {
        handleLogin(values.username, values.password);
      });
    }
  };

  return (
    <div className={styles['login-form-wrapper']}>
      <Logo variant={LogoVariants.BLUE_FULL_LOGO} className={styles['logo']} />
      <div className={styles['login-form-title']}>{t['login.form.title']}</div>
      <div className={styles['login-form-sub-title']}>
        {t['login.form.sub-title']}
      </div>
      <Form
        form={form}
        className={styles['login-form']}
        layout="vertical"
        initialValues={{ username: '', password: '' }}
      >
        <Form.Item
          field="username"
          rules={[
            { required: true, message: t['login.form.username.errMsg'] },
            { type: 'email', message: t['login.form.username.errEmailMsg'] }
          ]}
        >
          <Input
            prefix={<IconUser />}
            placeholder={t['login.form.username.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: t['login.form.password.errMsg'] }]}
        >
          <Input.Password
            prefix={<IconLock />}
            placeholder={t['login.form.password.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <div className={styles['login-form-password-actions']}>
            <Checkbox checked={rememberPassword} onChange={setRememberPassword}>
              {t['login.form.rememberPassword']}
            </Checkbox>
            <Link disabled>{t['login.form.forgetPassword']}</Link>
          </div>
          <Button
            type="primary"
            long
            onClick={onSubmitClick}
            loading={loginLoading}
          >
            {t['login.form.login']}
          </Button>
          <Button
            type="primary"
            disabled
            long
            className={styles['login-form-register-btn']}
          >
            {t['login.form.register']}
          </Button>
        </Space>
      </Form>
    </div>
  );
}
