import { LocaleError } from '../errors/errors';
import commonLocale from '../locale/commonLocale';
import { layoutSelectors } from '../redux/slices/layout.slice';
import { useAppSelector } from '../redux/store';

function useLocale(
  locale: Record<string, any> | null,
  useCommonLocale: boolean = false
) {
  const lang = useAppSelector(layoutSelectors.selectLang);
  const chosenLocale = useCommonLocale ? commonLocale : locale;
  if (!chosenLocale) {
    throw new LocaleError('Locale file is not found');
  }
  return chosenLocale[lang] || {};
}

export default useLocale;
